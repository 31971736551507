<template>
  <v-stepper v-model="stage" alt-labels width="100%">
    <div
      :class="{
        'd-flex flex-column align-center': $vuetify.breakpoint.xsOnly,
        'd-none': $vuetify.breakpoint.smAndUp,
      }"
      style="background: #fafafa; margin-bottom: 0"
      class="title-mobile-mode"
    >
      <sapn class="font-20-bo-666-32" style="font-size: 14px; margin-bottom: 0">
        ایجاد کسب‌وکار
      </sapn>
      <a
        class="font-11-no-333-17"
        target="_blank"
        href="https://cdn.vandar.io/public/files/create_business.pdf"
        style="color: #4c9f87"
      >
        راهنمایی ساخت کسب‌وکار
      </a>
    </div>
    <v-stepper-header class="stepper-header">
      <div
        :class="{
          'd-none': $vuetify.breakpoint.xsOnly,
          'd-flex flex-column justify-center': $vuetify.breakpoint.smAndUp,
        }"
      >
        <sapn class="font-20-bo-666-32 mb-2">ایجاد کسب‌وکار</sapn>
        <a
          class="font-11-no-333-17"
          target="_blank"
          href="https://cdn.vandar.io/public/files/create_business.pdf"
          style="color: #4c9f87"
        >
          راهنمایی ساخت کسب‌وکار
        </a>
      </div>
      <v-stepper-step :complete="stage > 1" step="1">
        اطلاعات پایه
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="stage > 2" step="2">
        اطلاعات مکانی
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="stage > 3" step="3">
        بارگذاری مدارک
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="4"> درگاه پرداخت </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content class="px-0" step="1">
        <StepperContentWrapper
          :is-first-button-disabled="isBasicInfoFormDisable"
          @confirm="confirmSendUpBasicInfoData"
        >
          <BasicInfo
            :should-send-up-data="shouldSendUpBasicInfo"
            @businessNameFaIsFocused="isShowBusinessNameFa = true"
            @businessNameFaIsBlurred="isShowBusinessNameFa = false"
            @businessNameIsFocused="isShowBusinessName = true"
            @businessNameIsBlurred="isShowBusinessName = false"
            @legalBusinessNameIsFocused="isShowLegalBusinessName = true"
            @legalBusinessNameIsBlurred="isShowLegalBusinessName = false"
            @categoryIdIsFocused="isShowCategoryId = true"
            @categoryIdIsBlurred="isShowCategoryId = false"
            @is-form-disable="isBasicInfoFormDisable = $event"
            @business-data="setBasicInfoData"
          />
          <!-- TODO: transition needed -->
          <GuidanceComponent
            v-show="isShowBusinessNameFa && !isMobileMode"
            guidance-title="راهنمای نام برند:"
            :guidance-items="businessNameFaGuidanceItems"
            class="mr-10 guidance-general-style"
            style="top: 8.3rem"
          />
          <GuidanceComponent
            v-show="isShowBusinessName && !isMobileMode"
            guidance-title="راهنمای نام انگلیسی برند:"
            :guidance-items="businessNameGuidanceItems"
            class="mr-10 guidance-general-style"
            style="top: 11.3rem"
          />
          <GuidanceComponent
            v-show="isShowLegalBusinessName && !isMobileMode"
            guidance-title="راهنمای نام حقوقی:"
            :guidance-items="legalBusinessNameGuidanceItems"
            class="mr-10 guidance-general-style"
            style="top: 15.3rem"
          />
          <GuidanceComponent
            v-show="isNationalIdFocused && !isMobileMode"
            guidance-title="راهنمای شناسه ملی:"
            :guidance-items="nationalIdGuidanceItems"
            class="mr-10 guidance-general-style"
            style="top: 17.8rem"
          />
          <GuidanceComponent
            v-show="isShowCategoryId && !isMobileMode"
            guidance-title="راهنمای حوزه فعالیت:"
            :guidance-items="mccCodeGuidanceItems"
            class="mr-10 guidance-general-style"
            :style="{
              top: determineHeight + 'rem',
            }"
          />
        </StepperContentWrapper>
      </v-stepper-content>

      <v-stepper-content class="px-0" step="2">
        <StepperContentWrapper
          :is-first-button-disabled="!isLocationInfoFormValid"
          first-button-text="تایید و ادامه"
          has-back-button
          @confirm="confirmSendUpLocationInfo"
          @skip="stage = 1"
        >
          <LocationInfo
            :should-send-up-location-data="shouldSendUpLocationInfo"
            @address-is-focused="isShowAddressGuidance = true"
            @address-is-blurred="isShowAddressGuidance = false"
            @is-location-info-form-valid="isLocationInfoFormValid = $event"
            @send-up-location-info="createBusiness"
          />
          <GuidanceComponent
            v-show="
              $store.state.newbusiness.isPostalCodeFieldFocused && !isMobileMode
            "
            guidance-title="راهنمای کد پستی:"
            :guidance-items="postalCodeGuidanceItems"
            class="mr-10 guidance-general-style"
            style="top: 2.3rem"
          />
          <GuidanceComponent
            v-show="isShowAddressGuidance && !isMobileMode"
            guidance-title="راهنمای آدرس:"
            :guidance-items="addressGuidanceItems"
            class="mr-10 guidance-general-style"
            style="top: 8.3rem"
          />
          <GuidanceComponent
            v-show="
              $store.state.newbusiness.isPhoneNumberFieldFocused &&
              !isMobileMode
            "
            guidance-title="راهنمای تلفن ثابت:"
            :guidance-items="phoneNumberGuidanceItems"
            class="mr-10 guidance-general-style"
            style="top: 11.3rem"
          />
        </StepperContentWrapper>
      </v-stepper-content>

      <v-stepper-content class="px-0" step="3">
        <StepperContentWrapper
          :is-first-button-disabled="
            !$store.state.newbusiness.isOnboardingFirstBtnActive
          "
          is-upload-doc-step
          has-second-button
          first-button-width="150"
          first-button-text="ذخیره"
          @confirm="saveUploadDocs"
          @skip="skipUploadDocs"
        >
          <UploadDocs @all-docs-uploaded="isAllLegalDocsUploaded = $event" />

          <GuidanceComponent
            guidance-title="هشدار"
            svg-name="warning-onboarding"
            :guidance-items="uploadDocsWarningItems"
            :class="{ 'mr-10 guidance-general-style': !isMobileMode }"
            :style="!isMobileMode ? 'top: 2.3rem' : ''"
          />
        </StepperContentWrapper>
      </v-stepper-content>

      <v-stepper-content class="px-0" step="4">
        <StepperContentWrapper
          :is-first-button-disabled="
            !$store.state.newbusiness.isOnboardingFirstBtnActive
          "
          is-upload-doc-step
          has-second-button
          first-button-text="ذخیره"
          first-button-width="150"
          @confirm="confirmToCreateIpg"
          @skip="skipToCreateIpg"
        >
          <CreatingIpgStep />
          <div
            v-if="isShowIpgGeneralGuidanceStep4"
            :class="{ 'mx-4 mb-4': isMobileMode }"
          >
            <ul
              v-if="!isMccCryptoCurrency"
              class="pl-0"
              :class="{ 'mr-10 guidance-general-style': !isMobileMode }"
              :style="!isMobileMode ? 'top: 2rem' : ''"
            >
              <div class="d-flex align-center">
                <v-img
                  width="32"
                  height="32"
                  max-width="32"
                  max-height="32"
                  :src="require(`@/assets/img/guide-icon.svg`)"
                />
                <span class="mr-2 font-12-bo-999-18">
                  راهنمای درگاه پرداخت:</span
                >
              </div>
              <li class="font_28 mr-10">
                برای دریافت درگاه پرداخت، داشتن اینماد الزامی‌ست.
              </li>
              <li class="font_28 mr-10">
                فرآیند دریافت اینماد
                <a
                  target="_blank"
                  href="https://vandar.io/blog/راهنمای-دریافت-اینماد-و-کد-رهگیری-مالی/"
                >
                  راهنمای دریافت
                </a>
              </li>
            </ul>
            <ul
              class="pl-0"
              :class="{ 'mr-10 guidance-general-style': !isMobileMode }"
              :style="
                !isMobileMode
                  ? isMccCryptoCurrency
                    ? 'top: 2rem'
                    : 'top: 9rem'
                  : ''
              "
            >
              <div class="d-flex align-center">
                <v-img
                  width="32"
                  height="32"
                  max-width="32"
                  max-height="32"
                  :src="require(`@/assets/img/warning-onboarding.svg`)"
                />
                <span class="mr-2 font-12-bo-999-18"> هشدار:</span>
              </div>
              <li class="font_28 mr-10">
                کسب‌وکارهای حوزه رمزارز نباید اینماد داشته باشند
              </li>
            </ul>
          </div>
          <GuidanceComponent
            v-show="showCategoryFieldGuidanceStep4() && !isMobileMode"
            guidance-title="راهنمای حوزه فعالیت:"
            :guidance-items="mccCodeGuidanceItemsStep4"
            class="mr-10 guidance-general-style"
            style="top: 2rem"
          />
          <GuidanceComponent
            v-show="showWebsiteUrlFieldGuidance() && !isMobileMode"
            guidance-title="راهنمای آدرس وب سایت:"
            :guidance-items="websiteUrlGuidanceItems"
            class="mr-10 guidance-general-style"
            style="top: 5.5rem"
          />
          <GuidanceComponent
            v-show="showTaxCodeFieldGuidance() && !isMobileMode"
            guidance-title="راهنمای کد رهگیری مالیاتی:"
            :guidance-items="taxCodeGuidanceItems"
            class="mr-10 guidance-general-style"
            style="top: 13.5rem"
          />
          <GuidanceComponent
            v-show="showIpsFieldGuidance() && !isMobileMode"
            guidance-title="راهنمای آی‌ پی:"
            :guidance-items="ipsGuidanceItems"
            class="mr-10 guidance-general-style"
            :style="isLegalBusiness ? 'top: 28rem' : 'top: 24.5rem'"
          />
          <GuidanceComponent
            v-show="showWageFieldGuidance() && !isMobileMode"
            guidance-title="راهنمای  کارمزد:"
            :guidance-items="wageGuidanceItems"
            class="mr-10 guidance-general-style"
            :style="isLegalBusiness ? 'top: 31rem' : 'top: 28rem'"
          />
          <GuidanceComponent
            v-show="showShebaFieldGuidance() && !isMobileMode"
            guidance-title="راهنمای شماره شبا:"
            :guidance-items="shebaGuidanceItems"
            class="mr-10 guidance-general-style"
            :style="isLegalBusiness ? 'top: 39rem' : 'top: 35.5rem'"
          />
          <GuidanceComponent
            v-show="showLogoFieldGuidance() && !isMobileMode"
            guidance-title="راهنمای لوگو:"
            :guidance-items="logoGuidanceItems"
            class="mr-10 guidance-general-style"
            :style="
              isShowBoxUnderShebaField
                ? `top: ${determineHeightOfLogoGuidance(53)}`
                : `top: ${determineHeightOfLogoGuidance(47)}`
            "
          />
        </StepperContentWrapper>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import BasicInfo from "./elements/BasicInfo.vue"
import LocationInfo from "./elements/LocationInfo.vue"
import GuidanceComponent from "./elements/GuidanceComponent.vue"
import StepperContentWrapper from "./elements/StepperContentWrapper.vue"
import UploadDocs from "./elements/UploadDocs.vue"
import CreatingIpgStep from "./elements/CreatingIpg/CreatingIpgStep.vue"
import { mapState, mapMutations } from "vuex"
import servicesRepository from "@/services/servicesRepository"

export default {
  name: "OnBoardingComponent",
  components: {
    BasicInfo,
    GuidanceComponent,
    LocationInfo,
    StepperContentWrapper,
    UploadDocs,
    CreatingIpgStep,
  },
  provide() {
    return {
      mccCodeGuidanceItemsStep4: this.mccCodeGuidanceItemsStep4,
      websiteUrlGuidanceItems: this.websiteUrlGuidanceItems,
      taxCodeGuidanceItems: this.taxCodeGuidanceItems,
      ipsGuidanceItems: this.ipsGuidanceItems,
      shebaGuidanceItems: this.shebaGuidanceItems,
      logoGuidanceItems: this.logoGuidanceItems,
    }
  },
  data() {
    return {
      stage: 1,
      businessData: {},
      isShowBusinessNameFa: false,
      isShowBusinessName: false,
      isShowLegalBusinessName: false,
      isShowCategoryId: false,
      cryptoCurrencyServiceCode: "78000001",
      businessNameFaGuidanceItems: [
        "نام کوتاه و اختصاری برند است",
        "نام باید خاص و منحصر به فرد باشد",
        "۲۰ کاراکتر اول در درگاه پرداخت شما نمایش داده می‌شود",
        "تا پیش از ثبت تراکنش، این نام قابل ویرایش است",
      ],
      businessNameGuidanceItems: [
        "نام کوتاه و اختصاری برندتان به انگلیسی است",
        "این نام پس از ثبت غیر قابل ویرایش است",
        "فراخوانی سرویس‌های شما با این نام انجام می‌شود",
      ],
      legalBusinessNameGuidanceItems: [
        "این نام باید در روزنامه رسمی شما ثبت شده باشد",
        "نام حقوقی مطابق نامی که در سامانه مالیاتی و اینماد ثبت شده می‌باشد",
      ],
      nationalIdGuidanceItems: [
        "این نام باید در روزنامه رسمی شما ثبت شده باشد",
        "نام حقوقی مطابق نامی که در سامانه مالیاتی و اینماد ثبت شده می‌باشد",
      ],
      mccCodeGuidanceItems: [
        "کد صنف ترمینال شما نزد شاپرک است",
        "حوزه فعالیت باید مطابق با اطلاعات سامانه مالیات و اینماد شما باشد",
        "در صورتیکه حوزه فعالیت نادرست باشد، درخواست درگاه پرداخت از سمت شاپرک رد می‌شود",
      ],
      isBasicInfoFormDisable: false,
      isLocationInfoFormValid: false,
      isShowAddressGuidance: false,
      postalCodeGuidanceItems: [
        "کد پستی که در سامانه مالیاتی و اینماد ثبت شده می‌باشد",
        "کد پستی باید با آدرس و شماره تلفن همخوانی داشته باشد",
      ],
      addressGuidanceItems: [
        "آدرس باید با کدپستی و شماره تلفن ثابت همخوانی داشته باشد",
      ],
      phoneNumberGuidanceItems: [
        "تلفن ثابت باید با آدرس و کد پستی همخوانی داشته باشد",
      ],
      uploadDocsWarningItems: [
        "بارگذاری این مدارک برای فعال‌سازی کسب‌وکار الزامی است",
        "اگر در حال حاضر به این مدارک دسترسی ندارید، پس از ورود به میزکار این بخش را تکمیل کنید.",
      ],
      mccCodeGuidanceItemsStep4: [
        "حوزه فعالیت اطلاعات مهمی است که در صورت صحیح نبودن درگاه پراخت شما از سمت شاپرک رد می‌شود",
        "حوزه فعالیت با آدرس وب سایت باید همخوانی داشته باشد",
      ],
      websiteUrlGuidanceItems: [
        "حوزه فعالیت با آدرس وب سایت باید همخوانی داشته باشد",
        "روی آدرس وب سایت وارد شده باید اینماد داشته باشید",
        "فقط آدرس اصلی را وارد کنید (مثال درست: example.com)",
      ],
      taxCodeGuidanceItems: [
        "درخواست فعال‌سازی درگاه پرداخت نیازمند داشتن کدرهگیری مالیاتی است.",
        "برای تایید درگاه سمت شاپرک لازمه که ثبت نام کد مالیاتی تا گام ۴ انجام شده باشد",
      ],
      ipsGuidanceItems: [
        "آدرس آی پی سرور درخواست دهنده پرداخت",
        "درخواست پرداخت تنها از طریق آدرس آی پی‌های وارد شده در این بخش قابل انجام خواهد بود",
        "این بخش پس از ثبت، امکان ویرایش دارد",
      ],
      wageGuidanceItems: [
        "کسر کارمزد از پرداخت کننده تراکنش یا از کیف پول شما",
        "این بخش پس از ثبت، امکان ویرایش دارد",
      ],
      shebaGuidanceItemsForLegalBusiness: [
        "شماره شبای حقوقی کسب‌وکار را وارد کنید",
        "این شماره شبا برای تسویه حساب روزانه مبالغ واریزی درگاه پرداخت شما استفاده خواهد شد",
        "این شماره شبا باید با شماره شبا ثبت شده در شاپرک همخوانی داشته باشد",
      ],
      shebaGuidanceItemsForRealBusiness: [
        "شماره شبا باید متعلق به خودتان باشد",
        "این شماره شبا برای تسویه حساب روزانه مبالغ واریزی درگاه پرداخت شما استفاده خواهد شد",
        "این شماره شبا باید با شماره شبا ثبت شده در شاپرک همخوانی داشته باشد",
      ],
      logoGuidanceItems: ["لوگو در درگاه پرداخت شما نمایش داده خواهد شد"],
      shouldSendUpBasicInfo: false,
      shouldSendUpLocationInfo: false,
      isShowIpgGeneralGuidanceStep4: true,
      isAllLegalDocsUploaded: false,
    }
  },
  computed: {
    ...mapState(["isNationalIdFocused"]),
    ...mapState("newbusiness", [
      "categoryFieldFocusState",
      "websiteUrlFieldFocusState",
      "taxCodeFieldFocusState",
      "wageFieldShowingState",
      "ipsFieldFocusState",
      "shebaFieldFocusState",
      "uploadFieldFocusState",
      "isShowBoxUnderShebaField",
    ]),

    determineHeight() {
      return this.$store.state.newbusiness.isHaghighi ? 14.5 : 21
    },
    isMobileMode() {
      return this.$vuetify.breakpoint.xsOnly
    },
    shebaGuidanceItems() {
      return this.$store.state.business.business_type === "legal"
        ? this.shebaGuidanceItemsForLegalBusiness
        : this.shebaGuidanceItemsForRealBusiness
    },
    isMccCryptoCurrency() {
      return (
        this.$store.state.business?.mcc_code === this.cryptoCurrencyServiceCode
      )
    },
    isLegalBusiness() {
      return this.$store.state.business.business_type === "legal"
    },
  },
  methods: {
    ...mapMutations("newbusiness", [
      "addBusiness",
      "changeCreatingIpgStatus",
      "changeOnboardingThirdStepDoneStatus",
      "changeOnboardingFourthStepDoneStatus",
      "changeOnboardingFirstBtnActiveState",
    ]),
    confirmSendUpBasicInfoData() {
      this.shouldSendUpBasicInfo = true
    },
    setBasicInfoData($event) {
      this.businessData = { ...$event }
      this.shouldSendUpBasicInfo = false
      this.goToStage(2)
    },
    confirmSendUpLocationInfo() {
      this.shouldSendUpLocationInfo = true
    },
    createBusiness($event) {
      this.shouldSendUpLocationInfo = false
      const payload = { ...this.businessData, ...$event }
      this.addBusiness(payload)
      servicesRepository(this)
        .businessServices.httpCreateBusiness(payload)
        .then((response) => {
          this.$store.commit("setBusiness", response.data.data)
          this.$store.commit("changeUpdateBusinessList", true)
          this.$store.commit("isUserOrNot", false)
          this.goToStage(3)
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    removeIpgGeneralGuidanceStep4() {
      this.isShowIpgGeneralGuidanceStep4 = false
    },
    checkToRemoveIpgGeneralGuidanceStep4(focusedFieldState) {
      if (focusedFieldState && this.isShowIpgGeneralGuidanceStep4) {
        this.removeIpgGeneralGuidanceStep4()
      }
    },
    showCategoryFieldGuidanceStep4() {
      this.checkToRemoveIpgGeneralGuidanceStep4(this.categoryFieldFocusState)
      return this.categoryFieldFocusState
    },
    showWebsiteUrlFieldGuidance() {
      this.checkToRemoveIpgGeneralGuidanceStep4(this.websiteUrlFieldFocusState)
      return this.websiteUrlFieldFocusState
    },
    showTaxCodeFieldGuidance() {
      this.checkToRemoveIpgGeneralGuidanceStep4(this.taxCodeFieldFocusState)
      return this.taxCodeFieldFocusState
    },
    showIpsFieldGuidance() {
      this.checkToRemoveIpgGeneralGuidanceStep4(this.ipsFieldFocusState)
      return this.ipsFieldFocusState
    },
    showWageFieldGuidance() {
      this.checkToRemoveIpgGeneralGuidanceStep4(this.wageFieldShowingState)
      return this.wageFieldShowingState
    },
    showShebaFieldGuidance() {
      this.checkToRemoveIpgGeneralGuidanceStep4(this.shebaFieldFocusState)
      return this.shebaFieldFocusState
    },
    showLogoFieldGuidance() {
      this.checkToRemoveIpgGeneralGuidanceStep4(this.uploadFieldFocusState)
      return this.uploadFieldFocusState
    },
    confirmToCreateIpg() {
      this.changeCreatingIpgStatus(true)
    },
    goToOnboardingResult() {
      this.$router.push({ name: "OnboardingResult" })
    },
    skipToCreateIpg() {
      this.changeOnboardingFourthStepDoneStatus(false)
      this.goToOnboardingResult()
    },
    saveUploadDocs() {
      this.goToStage(4)
      if (this.$store.state.business.business_type === "legal") {
        if (this.isAllLegalDocsUploaded) {
          this.changeOnboardingThirdStepDoneStatus(true)
        } else {
          this.changeOnboardingThirdStepDoneStatus(false)
        }
      } else {
        this.changeOnboardingThirdStepDoneStatus(true)
      }

      this.changeOnboardingFirstBtnActiveState(false)
    },
    skipUploadDocs() {
      this.goToStage(4)
      this.changeOnboardingThirdStepDoneStatus(false)
    },
    goToStage(stageNumber) {
      this.stage = stageNumber
    },
    determineHeightOfLogoGuidance(num) {
      const heigthForNotLegal = `${num}rem`
      const heigthForLegal = `${num + 2}rem`
      return this.isLegalBusiness ? heigthForLegal : heigthForNotLegal
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(.v-stepper__header) {
  background: #fafafa;
}

:deep(.v-stepper__label) {
  font-family: IRANSans;
  font-size: 14px;
  font-weight: bold;
}

:deep(.v-stepper__header) {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.title-mobile-mode {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.guidance-general-style {
  position: absolute;
  right: 33.5rem;
}

@media screen and (min-width: 1264px) {
  :deep(.v-stepper__wrapper) {
    padding-right: 300px;
  }
}

@media screen and (min-width: 1264px) {
  .stepper-header {
    padding-right: 300px;
    padding-left: 300px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1264px) {
  .stepper-header {
    padding-right: 100px;
    padding-left: 100px;
  }

  :deep(.v-stepper__wrapper) {
    padding-right: 100px;
  }

  .continue-button-style {
    margin-right: 0 !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .stepper-header {
    padding-right: 20px;
    padding-left: 20px;
  }

  :deep(.v-stepper__wrapper) {
    padding-right: 20px;
  }

  .continue-button-style {
    margin-right: 0 !important;
  }

  .guidance-general-style {
    position: absolute;
    right: 30rem;
  }
}

@media screen and (max-width: 425px) {
  .continue-button-style {
    margin-right: 52px !important;
  }
}

@media screen and (max-width: 375px) {
  .continue-button-style {
    margin-right: 28px !important;
  }
}

@media screen and (max-width: 360px) {
  .continue-button-style {
    margin-right: 20px !important;
  }
}

@media screen and (max-width: 320px) {
  .continue-button-style {
    margin-right: 0 !important;
  }
}
</style>

<style>
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.v-stepper--alt-labels .v-stepper__header {
  height: 190px !important;
  display: flex;
  align-items: center;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin-top: 82px !important;
}

@media screen and (max-width: 960px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: flex !important;
  }
}

@media screen and (max-width: 768px) {
  .v-stepper--alt-labels .v-stepper__header .v-divider {
    margin-top: 84px !important;
  }
}

@media screen and (max-width: 600px) {
  .v-stepper--alt-labels .v-stepper__header .v-divider {
    margin-top: 26px !important;
  }

  .v-stepper--alt-labels .v-stepper__header {
    height: auto !important;
  }

  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 100px !important;
  }

  .v-stepper__label {
    font-size: 12px !important;
  }

  .v-stepper__step {
    padding: 12px !important;
  }
}

@media screen and (max-width: 320px) {
  .v-stepper__label {
    font-size: 11px !important;
  }
}
</style>
