const apiHeaders = (context) => ({
  "Content-Type": "application/json",
  authorization: "Bearer " + context.$store.state.token,
  Accept: "application/json",
})

export default (context) => ({
  httpCheckIban(iban) {
    return context.$http.post(
      `${context.$store.getters.ibanApiUrlParam}/check`,
      iban,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpCheckPostalCode(payload) {
    return context.$http.post(
      `${context.$store.getters.apiUrlParam}/postalcode`,
      payload,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpPostCheckUniqueness(payload) {
    return context.$http.post(
      `${context.$store.state.api_base_url}/business/validation/uniqueness`,
      payload,
      {
        headers: apiHeaders(context),
      }
    )
  },
})
