<template>
  <v-card>
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">تنظیمات پرداخت یکپارچه</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          depressed
          outlined
          text
          dark
          class="btn_small_normal"
          @click="open_edit()"
        >
          ویرایش
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column>
      <v-flex>
        <v-layout column class="rows">
          <v-flex>
            <v-layout>
              <v-flex class="label"> وب‌سایت </v-flex>
              <v-flex>
                <div
                  v-for="(url, i) in $store.state.subscription.urls"
                  :key="i"
                >
                  {{ url }}
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout>
              <v-flex class="label"> کارمزد تراکنش </v-flex>
              <v-flex
                v-if="$store.state.subscription.wage_type == 'APPLICATION_SELF'"
              >
                از خودم کسر شود
              </v-flex>
              <v-flex v-else> از پرداخت کننده دریافت شود </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- modals -->
    <v-dialog
      v-model="status1"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <add
        v-show="status1"
        :key="componentKey"
        type="edit"
        @closeModal="close_modal"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import add from "./add"
export default {
  name: "Show",
  components: {
    add,
  },
  data: function () {
    return {
      componentKey: 0,
      status1: false,
    }
  },
  watch: {
    status1: function (newValue) {
      if (!newValue) this.$store.commit("callEditSubFormModal", false)
    },
  },
  methods: {
    open_edit() {
      this.close_modal()
      this.$store.commit("callEditSubFormModal", true)
      this.status1 = true
    },
    close_modal(obj) {
      this.$store.commit("callEditSubFormModal", false)
      this.status1 = false
      if (obj && obj.status) this.componentKey = !this.componentKey
    },
  },
}
</script>

<style lang="scss" scoped>
nav.v-toolbar > .v-toolbar__content {
  padding: 0 7px 0 7px !important;
}
.v-tooltip__content {
  min-width: auto !important;
  top: 39px !important;
  padding: 5px 8px !important;
}
.copyBtn {
  border: 1px solid #666;
  border-radius: 4px;
}
</style>
