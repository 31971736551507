<script>
import NationalCode from "@/components/inputs/nationalCode.vue"
import screenSize from "@/mixins/screenSize.js"

export default {
  name: "NationalCodeField",
  components: { NationalCode },
  mixins: [screenSize],
}
</script>

<template>
  <v-layout
    class="mt-8 pa-0 justify-space-between"
    :class="{ 'd-flex flex-column': reuseIsMobile }"
  >
    <v-flex class="label"> کد ملی </v-flex>
    <v-flex class="flex-column withlabel big">
      <NationalCode @setValue="$emit('set-national-code', $event)" />
    </v-flex>
  </v-layout>
</template>

<style scoped lang="scss"></style>
