export default {
  namespaced: true,
  state: {
    isShowCreationTicketModal: false,
    shouldSetAccountantCategory: false,
  },
  mutations: {
    SET_IS_SHOW_CREATION_TICKET_MODAL(state, payload) {
      state.isShowCreationTicketModal = payload
    },
    TOGGLE_SHOULD_SET_ACCOUNTANT_CATEGORY(state, payload) {
      state.shouldSetAccountantCategory = payload
    },
  },
  actions: {
    setIsShowCreationTicketModal({ commit, dispatch }, payload) {
      commit("SET_IS_SHOW_CREATION_TICKET_MODAL", payload)
      if (!payload) {
        dispatch("toggleShouldSetAccountantCategory", false)
      }
    },
    toggleShouldSetAccountantCategory({ commit }, payload) {
      commit("TOGGLE_SHOULD_SET_ACCOUNTANT_CATEGORY", payload)
    },
  },
}
