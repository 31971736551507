import Vue from "vue"
import Vuex from "vuex"
import { initialState } from "./state.js"
import { actions } from "./actions.js"
import { mutations } from "./mutations.js"
import { getters } from "./getters.js"
import newbusiness from "./modules/newbusiness.js"
import ipg from "./modules/ipg.js"
import subscription from "./modules/subscription.js"
import report from "./modules/report.js"
import customer from "./modules/customer.js"
import pbv from "./modules/pbv.js"
import miando from "./modules/miando"
import mpg from "./modules/mpg.js"
import bankInvoice from "./modules/bankInvoice.js"
import ticket from "./modules/ticket.js"

Vue.use(Vuex)
export const store = new Vuex.Store({
  //strict: process.env.NODE_ENV !== 'production',
  //namespaced : true,
  state: initialState(),
  mutations,
  actions,
  getters,
  modules: {
    newbusiness,
    ipg,
    subscription,
    report,
    customer,
    pbv,
    miando,
    mpg,
    bankInvoice,
    ticket,
  },
})
