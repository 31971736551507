<template>
  <div class="box-container">
    <h3 class="font-11-bo-333-17">
      محدودیت {{ limitationPrice }} میلیون تومانی
    </h3>
    <p class="font-11-no-999-18">
      برای ارسال تراکنش‌ها به بانک، محدودیت {{ limitationPrice }} میلیون تومانی
      وجود دارد، در نتیجه درخواست برداشت شما با مبلغ،
      {{ reuseConvertCurrencyForTomanAmount(netPrice) | thousandSeprator }}
      به {{ count }} تراکنش شکسته شده که در لینک زیر قابل مشاهده هستند.
    </p>

    <v-divider class="mt-3 mb-4" style="border-style: dashed" />

    <div v-if="reuseIsMobile" class="d-flex flex-column">
      <div class="d-flex align-center justify-space-between mb-1">
        <span class="font-11-no-999-18">لینک کل رسیدها</span>
        <CopyBtnGeneral
          input-id="hidden-input"
          :value="specifyLink"
          label="کپی"
          color-btn="van_color06"
          class-btn="tblCpy-receipt_url px-2 ma-0"
          outlined
        />
      </div>

      <div class="d-flex align-center justify-end">
        <a :href="specifyLink" target="_blank" class="link-style">
          {{ specifyLink }}
        </a>
      </div>
    </div>
    <div v-else class="d-flex align-center justify-space-between">
      <span class="font-11-no-999-18">لینک کل رسیدها</span>
      <div class="d-flex align-center">
        <a :href="specifyLink" target="_blank" class="ml-5 link-style">
          {{ specifyLink }}
        </a>
        <CopyBtnGeneral
          input-id="hidden-input"
          :value="specifyLink"
          label="کپی"
          color-btn="van_color06"
          class-btn="tblCpy-receipt_url px-2 ma-0"
          outlined
        />
      </div>
    </div>
  </div>
</template>

<script>
import CopyBtnGeneral from "@/components/elements/copyBtnGeneral.vue"
import currencyConvert from "@/mixins/currencyConvert.js"
import screenSize from "@/mixins/screenSize.js"

export default {
  name: "AggregateTransactionLimitationInfo",
  components: {
    CopyBtnGeneral,
  },
  mixins: [currencyConvert, screenSize],
  data() {
    return {
      link: "https://vand.ar/abc123",
    }
  },
  computed: {
    specifyLink() {
      return `${process.env.VUE_APP_RECEIPT_BASE_URL}${this.$store.state.report.aggregateTransactionData.receipt_code}`
    },
    netPrice() {
      return (
        this.$store.state.report.aggregateTransactionData.aggregate_price -
        this.$store.state.report.aggregateTransactionData.aggregate_wage
      )
    },
    limitationPrice() {
      return (
        this.$store.state.report.aggregateTransactionData.separate_amount /
        1000000
      )
    },
    count() {
      return this.$store.state.report.aggregateTransactionData.count
    },
  },
}
</script>

<style scoped lang="scss">
.box-container {
  margin: 0 30px 20px 30px;
  padding: 30px;
  background-color: $van_color13;
  border: 1px solid $van_color11;
  border-radius: 4px;
}

.link-style {
  text-decoration: none;
  border-bottom: 1px dashed $van_green;
}

@media screen and (max-width: 599px) {
  .box-container {
    margin: 10px 20px 20px 20px;
  }
}
</style>
