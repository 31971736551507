<template>
  <left-modal :toolbar-name="toolbarName" @closeModal="$emit('closeModal')">
    <div slot="toolbarItems">
      <v-btn
        dark
        text
        class="btn_small_normal"
        :disabled="
          !((!a2aIsActive && showNextStep) || settlementMethods) ||
          isAlreadyExists
        "
        @click="openConfirmModal()"
      >
        {{ registerBtnText }}
      </v-btn>
    </div>

    <template v-if="!seenQueuedInfo" #subToolbar>
      <van-modal-announcement-bar
        :description="descriptions.queuedInfo"
        container-classes="px-8 py-3 van_pink van_color13--text font-size-12 justify-space-between "
      >
        <template #iconSlot>
          <img
            width="24px"
            height="24px"
            src="@/assets/img/newIcon/whatsnew-white.svg"
          />
          <span class="mr-2 font-size-14 bold">جدید</span>
        </template>
        <template #actionSlot>
          <span class="dashed-border__white">
            <a class="van_color13--text" @click="ShowQueuedInfoModal()"
              >جزییات</a
            >
          </span>
        </template>
      </van-modal-announcement-bar>
    </template>

    <general-card>
      <div class="d-flex mb-5">
        <div class="flex label d-flex align-center">
          بارگذاری اکسل
          <span class="mr-1">*</span>
        </div>
        <VanUploadBox
          ref="uploadComponent"
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @onChange="onFileChange"
        />
      </div>

      <template v-if="showNextStep">
        <div v-if="isQueued" class="d-flex mb-4">
          <div class="flex label"></div>
          <div class="withlabel big">
            <van-modal-announcement-bar
              :description="descriptions.activeQueued"
              container-classes="yellow-color-2__bg font_2 van_color03--text px-3 py-2  radius-4"
            >
              <template #actionSlot>
                <span style="border-bottom: 1px dashed #fff">
                  <a
                    class="ma-0 van_color03--text dashed-border__666"
                    @click="ShowQueuedInfoModal()"
                    >جزییات</a
                  >
                </span>
              </template>
            </van-modal-announcement-bar>
          </div>
        </div>

        <div v-if="a2aIsActive" class="d-flex">
          <div class="flex label">
            روش برداشت
            <span>*</span>
          </div>
          <div class="withlabel big">
            <v-radio-group
              v-model="settlementMethods"
              style="margin: 0 !important"
              class="simpleRadio"
              column
            >
              <van-settlement-method-radio-btn
                v-for="(element, index) in settlementMethodsArray"
                :key="element + index"
                v-bind="element"
              >
                <div v-if="element.value === 'SMART'" slot="extraInfoSlot">
                  <v-btn
                    class="small ma-0"
                    text
                    :ripple="false"
                    @click="$emit('openSmartModal')"
                  >
                    <span class="linkBorder font_37 van_green--text ml-0">
                      جزییات
                    </span>
                  </v-btn>
                </div>
                <van-modal-announcement-bar
                  v-if="element.value === 'SMART'"
                  slot="infoBox"
                  :description="descriptions.smartInfo"
                  container-classes="van_color11 font_2 van_color03--text px-3 py-2 radius-4 mb-3"
                >
                </van-modal-announcement-bar>
              </van-settlement-method-radio-btn>
            </v-radio-group>
          </div>
        </div>

        <div class="d-flex">
          <div class="flex label"></div>
          <van-switch-with-info
            v-if="!isQueued"
            :description="switchInfoBoxText(isCancelable)"
            :disabled="a2aIsActive && !settlementMethods"
            @onSwitchToggle="onSwitchToggle($event)"
          />
          <van-modal-announcement-bar
            v-else
            slot="infoBox"
            :description="descriptions.queuedCancel"
            :container-classes="`${
              settlementMethods || !a2aIsActive
                ? 'green_back_1'
                : 'van_color11 '
            } withlabel big font_2 van_color03--text px-3 py-2 radius-4 mb-3`"
          >
          </van-modal-announcement-bar>
        </div>
      </template>
      <template v-else-if="isFileUploaded">
        <div class="d-flex">
          <div class="flex label">
            روش برداشت
            <span>*</span>
          </div>
          <div class="withlabel big w-100">
            <v-skeleton-loader
              class="methods-skeleton"
              max-width="100%"
              tile
              type="list-item-two-line"
            ></v-skeleton-loader>
          </div>
        </div>
      </template>
    </general-card>

    <general-dialog
      :active="showErrorsModal && modalErrors.length !== 0"
      logo="newIcon/warning.svg"
      content-class="confirmationMessageDialog"
    >
      <template slot="extra">
        <div v-if="modalErrors.length === 1" class="w-100">
          <div
            v-for="(error, index) in modalErrors"
            :key="error + index"
            class="d-flex flex-column align-center mt-3"
          >
            <div v-if="error.title" class="font_9 text-center">
              {{ error.title }}
            </div>
            <div v-if="error.description" class="font_2 mt-2 text-center">
              {{ error.description }}
              <div class="d-flex justify-center">
                <v-btn
                  v-if="error.primaryBtn?.action"
                  text
                  class="btn_small_normal"
                  dark
                  @click="handle_function_call(error.primaryBtn.action)"
                >
                  {{ error.primaryBtn.text }}
                </v-btn>
                <v-btn
                  v-if="error.secondBtn"
                  text
                  outlined
                  @click="handle_function_call(error.secondBtn.action)"
                >
                  انصراف
                </v-btn>
                <v-btn v-else text outlined @click="showErrorsModal = false">
                  انصراف
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="w-100">
          <div v-if="multipleErrorsTitle" class="font_9 text-center">
            {{ multipleErrorsTitle }}
          </div>
          <div v-if="multipleErrorsDescription" class="font_2 mt-2 text-center">
            {{ multipleErrorsDescription }}
          </div>
          <div class="pa-5 van_color11 mt-3">
            <div
              v-for="(item, i) in modalErrors"
              :key="item + i"
              :class="` font_31 van_color04--text py-3 dashed-border ${
                i === 0 ? 'pt-0' : ''
              } ${i === modalErrors.length - 1 ? 'pb-0 border-0' : ''}`"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="d-flex justify-center">
            <v-btn
              text
              class="btn_small_normal"
              dark
              @click="
                errorsFile.length > 0 ? exportErrorFile() : openUploadWindow()
              "
            >
              {{ errorsFile.length > 0 ? "دریافت فایل" : "بارگذاری مجدد" }}
            </v-btn>
            <v-btn text outlined @click="showErrorsModal = false">
              انصراف
            </v-btn>
          </div>
        </div>
      </template>
    </general-dialog>

    <general-dialog
      :active="showConfirmModal"
      :logo="isQueued ? 'box-gray-past-time.svg' : 'newIcon/info.svg'"
      :title="
        isQueued
          ? 'آیا این برداشت را در نوبت قرار می‌دهید؟'
          : 'از انجام این برداشت اطمینان دارید؟'
      "
      content-class="confirmationMessageDialog"
      :description="
        isQueued
          ? 'با در نوبت قرار گرفتن این تراکنش‌ها، پس از بالا رفتن موجودی کیف‌پول، برداشت گروهی انجام می‌شود.'
          : ''
      "
      :btn1-text="isQueued ? 'بله، ثبت شود' : 'انجام شود'"
      :btn1-loading="sendingSettlement"
      :btn2-text="isQueued ? 'خیر، ویرایش می‌کنم' : 'منصرف شدم'"
      btn2-class="btn_small_normal"
      @btn1Clicked="checkForDuplicateBatchAndSend"
      @btn2Clicked="showConfirmModal = false"
    >
      <template slot="extra">
        <div class="pa-5 van_color11 mt-3 w-100">
          <div
            class="d-flex justify-space-between font_31 van_color04--text pb-3 dashed-border"
          >
            <span>مبلغ برداشت‌ها</span>
            <span class="font_7">
              {{ amountSumInCurrency }} {{ currencyName }}</span
            >
          </div>
          <div
            :class="`d-flex justify-space-between font_31 van_color04--text pt-3  ${
              isQueued ? 'dashed-border' : ''
            }`"
          >
            <span>تعداد برداشت‌ها</span>
            <span class="font_7"> {{ excelRecords?.length }} </span>
          </div>
          <div
            v-if="isQueued"
            class="d-flex justify-space-between font_31 van_color04--text pt-3"
          >
            <span>امکان لغو</span>
            <span class="font_7"> تا قبل از افزایش موجودی کیف‌پول</span>
          </div>
        </div>
      </template>
    </general-dialog>

    <general-dialog
      v-if="successfullyQueued"
      :active="successfullyQueued"
      logo="box-grren-past-time.svg"
      content-class="confirmationMessageDialog"
      title="برداشت گروهی در نوبت قرار گرفت"
      description="پس از بالا رفتن موجودی کیف‌پول و یا حساب، برداشت در صف بانک قرار می‌گیرد. برداشت‌های در نوبت را از طریق «میزکار» مشاهده کنید"
      :width="360"
      btn1-text="مشاهده در میز کار"
      btn2-text="بازگشت"
      @btn1Clicked="goToQueuedOnDesktop()"
      @btn2Clicked="closeModalAndReset()"
    >
    </general-dialog>
  </left-modal>
</template>

<script>
import leftModal from "@/components/modals/leftModal.vue"
import generalCard from "@/components/elements/generalCard"
import VanUploadBox from "./VanUploadBox"
import generalDialog from "@/components/modals/generalDialog"
import VanSettlementMethodRadioBtn from "@/components/VANComponents/vanSettlementMethodRadioBtn"
import vanSwitchWithInfo from "../../VANComponents/vanSwitchWithInfo.vue"
import vanModalAnnouncementBar from "../../VANComponents/vanModalAnnouncementBar.vue"

import XLSX from "xlsx"
import { v4 as uuidv4 } from "uuid"
import { SHA512 } from "crypto-js"

export default {
  name: "UploadModal",
  components: {
    generalCard,
    leftModal,
    VanUploadBox,
    generalDialog,
    VanSettlementMethodRadioBtn,
    vanSwitchWithInfo,
    vanModalAnnouncementBar,
  },
  props: {
    maxRecordsCount: {
      default: 1000,
      type: Number,
    },
    sampleFileCdn: {
      default: "",
      type: String,
    },
    minAmountEachRecord: {
      default: 50000, //tooman
      type: Number,
    },
    maxAmountEachRecord: {
      default: 100000000, // 100 m tooman
      type: Number,
    },
  },
  data() {
    return {
      uploadedFile: null,
      modalErrors: [],
      errorsFile: [],
      showErrorsModal: false,
      ibanHeader: null,
      amountHeader: null,
      descriptionHeader: null,
      commentHeader: null,
      trackIdHeader: null,
      paymentNumberHeader: null,
      sourceHeader: null,
      accountHeader: null,
      reasonCodeHeader: null,
      nationalCodeHeader: null,
      birthdateHeader: null,
      headers: [],
      excelRecords: null,
      trackIdList: [],
      isFileUploaded: false,
      fetchedCancelTime: false,
      settlementMethods: null,
      isCancelable: false,
      cancelableSwitch: false,
      showConfirmModal: false,
      timePrediction: "...",
      amountSumInCurrency: 0,
      sendingSettlement: false,
      isQueued: false,
      successfullyQueued: false,
      toolbarName: "بارگذاری فایل",
      registerBtnText: "ثبت درخواست",
      wrongIban: "شماره شبا را صحیح وارد کنید",
      exceedIbanCelling: "مبلغ وارد شده بیشتر از سقف شبا است",
      wrongPaymentNumber: "شناسه واریز را صحیح وارد کنید",
      duplicatedTrackId: "شناسه تسویه تکراری است",
      wrongAmount: "مبلغ را صحیح وارد کنید",
      minAmountError: `کف واریز به هر شماره شبا، ${this.minAmountEachRecord} تومان است.`,
      maxAmountError: `سقف واریز به هر شماره شبا، ${this.maxAmountEachRecord} تومان است.`,
      multipleErrorsTitle: "فایل بارگذاری شده مشکل دارد",
      multipleErrorsDescription: "مشکلات زیر در فایل بارگذاری شده وجود دارد.",
      ibanKeys: ["شماره شبا", "iban", "Iban", "IBAN"],
      amountKeys: ["مبلغ", "amount", "Amount", "AMOUNT"],
      isAlreadyExists: false,
      trackIdKeys: [
        "شناسه تسویه",
        "Track Id",
        "track id",
        "TRACK ID",
        "Track id",
        "track Id",
      ],
      paymentNumberKeys: [
        "شناسه واریز",
        "شناسه پرداخت",
        "payment number",
        "Payment number",
        "payment Number",
        "Payment Number",
        "PAYMENT NUMBER",
      ],
      descriptionKeys: ["توضیحات", "description", "Description", "DESCRIPTION"],
      commentKeys: ["یادداشت محرمانه", "comment", "Comment", "COMMENT"],
      sourceKeys: ["مبدا", "source", "Source", "SOURCE"],
      accountKeys: ["شماره شبا مبدا", "account", "Account", "ACCOUNT"],
      reasonCodeKeys: [
        "کد بابت",
        "reason code",
        "Reason code",
        "REASON CODE",
        "reason_code",
      ],
      nationalCodeKeys: [
        "کدملی",
        "کد ملی",
        "national code",
        "National code",
        "NATIONAL CODE",
        "nationalCode",
      ],
      birthdateKeys: [
        "تاریخ تولد",
        "birthdate code",
        "Birthdate code",
        "BIRTHDATE CODE",
        "birthdateCode",
      ],
      validFormats: [
        ".csv",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
      descriptions: {
        queuedInfo:
          "امکان ثبت درخواست برداشت در نوبت، در صورت نداشتن موجودی کافی.",
        activeQueued:
          " به علت کمبود موجودی کیف‌پول، در صورت ثبت درخواست، تراکنش بعد از افزایش موجودی در صف بانک قرار می‌گیرد.",
        smartInfo: "ثبت هوشمند برداشت، به صورت پایا یا تسویه آنی",
        queuedCancel:
          "شما تا بالا رفتن موجودی کیف‌پول، می‌توانید درخواست خود را لغو کنید.",
        batchSettlementList: [],
      },
    }
  },
  computed: {
    a2aIsActive() {
      return this.$store.state.business?.tools?.A2A?.status === "ACTIVE"
    },
    settlementMethodsArray() {
      let result = []
      if (this.a2aIsActive) {
        result.push({
          label: "هوشمند",
          value: "SMART",
          show: this.a2aIsActive,
          disable: false,
          skeleton: false,
          extraInfo: null,
          shake: false,
        })
      }

      result.push({
        label: "پایا",
        value: "ACH",
        show: true,
        disable: false,
        skeleton: false,
        extraInfo: null,
        shake: false,
      })
      return result
    },
    showNextStep() {
      return this.fetchedCancelTime && this.isFileUploaded
    },
    seenQueuedInfo() {
      if (this.$store.state.isUser) return true

      return this.$store.state.user?.actions?.find(
        (item) => item.action_name === "queued_batch_settlement_onboarding"
      )
    },
  },
  methods: {
    closeModal() {
      this.resetData()
      this.$emit("closeModal")
    },
    noValidRowError() {
      return {
        title: "هیچ کدام از ردیف‌ها قابل پردازش نیستند",
        description:
          "اطلاعات فایل به درستی وارد نشده است، در صورت نیاز فایل نمونه را دریافت کنید.",
        primaryBtn: {
          text: "دریافت فایل",
          action: "downloadSampleFile",
        },
      }
    },
    recordsValidationError() {
      return {
        title: "ردیف‌هایی از فایل مشکل دارند",
        description:
          "برای مشاهده ردیف‌هایی که مشکل دارند، فایل جزییات را دریافت کنید.",
        primaryBtn: {
          text: "دریافت فایل",
          action: "exportErrorFile",
        },
      }
    },
    titlesValidationError() {
      return {
        title: "ستون‌های فایل مشکل دارند",
        description:
          "عنوان‌ هر ستون را بر اساس محتوای آن ستون اصلاح کرده یا از عدم تکرار اطلاعات مطمئن شوید. در صورت نیاز فایل نمونه را دریافت کنید.",
        primaryBtn: {
          text: "دریافت فایل",
          action: "downloadSampleFile",
        },
      }
    },
    notValidFile() {
      return {
        title: "فایل بارگذاری شده مشکل دارد",
        description:
          "فرمت فایل انتخابی باید اکسل باشد، در صورت نیاز فایل نمونه را دریافت کنید.",
        primaryBtn: {
          text: "دریافت فایل",
          action: "downloadSampleFile",
        },
      }
    },
    duplicatedBatchId() {
      return {
        title: "فایل بارگذاری شده تکراری است",
        description:
          "شما قبلا این فایل را بارگذاری کرده‌اید، شناسه‌‌های تسویه وارد شده تکراری است، لطفا فایل را اصلاح کنید.",
        primaryBtn: {
          text: "بارگذاری مجدد",
          action: "openUploadWindow",
        },
        secondBtn: {
          action: "closeModal",
        },
      }
    },
    resetData() {
      this.$refs.uploadComponent.removeFile()
      this.modalErrors = []
      this.trackIdList = []
      this.errorsFile = []
      this.headers = []
      this.ibanHeader = null
      this.amountHeader = null
      this.descriptionHeader = null
      this.commentHeader = null
      this.trackIdHeader = null
      this.paymentNumberHeader = null
      this.sourceHeader = null
      this.accountHeader = null
      this.reasonCodeHeader = null
      this.nationalCodeHeader = null
      this.birthdateHeader = null
      this.excelRecords = null
      this.isFileUploaded = false
      this.sendingSettlement = false
      this.fetchedCancelTime = false
      this.timePrediction = "..."
      this.settlementMethods = null
      this.isCancelable = false
      this.amountSumInCurrency = 0
      this.uploadedFile = null
      this.isQueued = false
      this.successfullyQueued = false
      this.isAlreadyExists = false
    },
    openUploadWindow() {
      this.$refs.uploadComponent.fireClick()
    },
    onFileChange(file) {
      this.resetData()

      this.uploadedFile = file
      if (!file) {
        this.resetData()
        return
      }

      if (!this.validFormats.includes(file.type)) {
        this.resetData()
        this.pushErrorAndSetFailed(this.notValidFile())
        this.showErrorsModal = true

        return
      }
      const reader = new FileReader()

      reader.onload = async (e) => {
        /* Parse data */
        const bstr = e.target.result
        setTimeout(() => {
          const wb = XLSX.read(bstr, { type: "array" })
          const wsName = wb.SheetNames[0]
          const ws = wb.Sheets[wsName]

          const range = ws["!ref"]
          /* Extract headers and data in one pass */
          const headersSheetData = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            range,
          })
          const headers = headersSheetData.shift()

          this.excelRecords = XLSX.utils.sheet_to_json(ws, {
            range,
          })

          if (!this.excelRecords.length) {
            this.pushErrorAndSetFailed(this.noValidRowError())
            this.showErrorsModal = true
          } else {
            this.headers = headers
            this.cancelTimePrediction()
            this.validateExcelFile()

            this.createBatchSettlementList()
            const batchId = this.createBatchId(this.batchSettlementList)
            this.$http
              .post(
                this.$store.getters.apiWithVersionParam("3") +
                  "/settlement/queued/check",
                {
                  group_track_id: batchId,
                }
              )
              .then((res) => {
                if (res.data.data.is_already_exists) {
                  this.$store.commit(
                    "callsnackbar",
                    "فایل بارگذاری شده تکراری است"
                  )
                  this.isFileUploaded = false
                  this.isAlreadyExists = true
                  this.$refs.uploadComponent.setFailedStatus()
                }
              })
          }
        }, 50)
      }
      reader.readAsArrayBuffer(file)
    },
    downloadSampleFile() {
      window.open(
        "https://cdn.vandar.io/public/files/sample-batch-settlement.xlsx"
      )
    },
    validateExcelFile() {
      if (!this.validateHeaders()) {
        this.$refs.uploadComponent.setFailedStatus()
        this.showErrorsModal = true
        return
      }

      if (this.excelRecords.length > 1000) {
        this.pushErrorAndSetFailed(
          this.exceedMaxCountError(this.excelRecords.length)
        )
      }

      let amountSumInRial = this.validateEachRecord()

      this.amountSumInCurrency =
        this.$options.filters.currencyWithRialInput(amountSumInRial)

      this.isQueued = !this.hasEnoughMoney(amountSumInRial)

      if (!this.modalErrors.length) {
        this.$refs.uploadComponent.setUploadedStatus()
        this.isFileUploaded = true
      } else {
        this.showErrorsModal = true
      }
    },
    validateEachRecord() {
      let amountSumInRial = 0

      for (let i = 0; i < this.excelRecords.length; i++) {
        if (!isNaN(this.excelRecords?.[i]?.[this.amountHeader]))
          amountSumInRial += this.excelRecords[i][this.amountHeader]

        this.validateIban(i)
        this.validateAmount(i)

        if (this.paymentNumberHeader) this.validatePaymentNumber(i)
        if (this.trackIdHeader) this.validateTrackId(i)
      }
      if (this.errorsFile.length) {
        this.pushErrorAndSetFailed(this.recordsValidationError())
      }
      return amountSumInRial
    },
    validateIban(rowNumber) {
      const ibanCell = this.excelRecords[rowNumber][this.ibanHeader]
        ?.toString()
        .trim()
        .replace(/\r?\n|\r/g, "")

      function iso7064Mod97_10(iban) {
        var remainder = iban,
          block

        while (remainder.length > 2) {
          block = remainder.slice(0, 9)
          remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length)
        }

        return parseInt(remainder, 10) % 97
      }

      function validateIranianSheba(str) {
        let pattern = /IR[0-9]{24}/

        if (str?.length !== 26) {
          return false
        }

        if (!pattern.test(str)) {
          return false
        }

        let newStr = str.substr(4)
        let d1 = str.charCodeAt(0) - 65 + 10
        let d2 = str.charCodeAt(1) - 65 + 10
        newStr += d1.toString() + d2.toString() + str.substr(2, 2)

        let remainder = iso7064Mod97_10(newStr)
        return remainder === 1
      }

      if (!validateIranianSheba(ibanCell)) {
        this.pushToErrorsFile(rowNumber, this.wrongIban)
      }
    },
    validateAmount(rowNumber) {
      const amountCell = this.excelRecords?.[rowNumber]?.[this.amountHeader]

      if (amountCell < this.minAmountEachRecord * 10)
        this.pushToErrorsFile(
          rowNumber,
          this.$options.filters.thousandSeprator(this.minAmountError)
        )
      else if (amountCell > this.maxAmountEachRecord * 10)
        this.pushToErrorsFile(
          rowNumber,
          this.$options.filters.thousandSeprator(this.maxAmountError)
        )
      else if (isNaN(amountCell))
        this.pushToErrorsFile(rowNumber, this.wrongAmount)
      else if (
        this.$store.state?.businessCeilingAmount > -1 &&
        amountCell > this.$store.state?.businessCeilingAmount
      ) {
        this.pushToErrorsFile(rowNumber, this.exceedIbanCelling)
      }
    },
    validatePaymentNumber(rowNumber) {
      const paymentNumberCell =
        this.excelRecords[rowNumber][this.paymentNumberHeader]
      if (paymentNumberCell === undefined) return

      if (isNaN(paymentNumberCell)) {
        this.pushToErrorsFile(rowNumber, this.wrongPaymentNumber)
      }
    },
    validateTrackId(rowNumber) {
      const trackIdCell = this.excelRecords[rowNumber][this.trackIdHeader]
      if (trackIdCell === undefined) return

      if (this.trackIdList.includes(trackIdCell)) {
        this.pushToErrorsFile(rowNumber, this.duplicatedTrackId)
      } else this.trackIdList.push(trackIdCell)
    },
    validateHeaders() {
      if (!this.headers.length) {
        this.pushErrorAndSetFailed(this.titlesValidationError())
        this.pushToErrorsFile()
        this.showErrorsModal = true
        return false
      }

      this.ibanHeader = this.findHeaderExact(this.ibanKeys)
      this.amountHeader = this.findHeader(this.amountKeys)
      this.descriptionHeader = this.findHeader(this.descriptionKeys)
      this.commentHeader = this.findHeader(this.commentKeys)
      this.trackIdHeader = this.findHeader(this.trackIdKeys)
      this.paymentNumberHeader = this.findHeader(this.paymentNumberKeys)
      this.sourceHeader = this.findHeaderExact(this.sourceKeys)
      this.accountHeader = this.findHeader(this.accountKeys)
      this.reasonCodeHeader = this.findHeader(this.reasonCodeKeys)
      this.nationalCodeHeader = this.findHeader(this.nationalCodeKeys)
      this.birthdateHeader = this.findHeader(this.birthdateKeys)

      if (this.ibanHeader.length !== 1 || this.amountHeader.length !== 1) {
        this.pushErrorAndSetFailed(this.titlesValidationError())
        this.showErrorsModal = true
        return false
      }
      return true
    },
    pushToErrorsFile(row, description) {
      this.errorsFile.push({ row: row, description: description })
    },
    findHeader(columnKeys) {
      return this.headers.filter((header) => {
        return columnKeys.some((v) => {
          let regex = new RegExp(v)
          return regex.test(header)
        })
      })
    },
    findHeaderExact(columnKeys) {
      return this.headers.filter((header) => {
        return columnKeys.some((v) => {
          return v === header
        })
      })
    },
    pushErrorAndSetFailed(errorInfo) {
      this.modalErrors.push(errorInfo)
      this.$refs.uploadComponent.setFailedStatus()
    },
    hasEnoughMoney(amountSum) {
      return (
        amountSum <= this.$store.state.business.wallet_deductible_amount * 10
      )
    },
    exportErrorFile() {
      if (!this.errorsFile || this.errorsFile.length === 0) return

      var errors = []
      var wb = XLSX.utils.book_new()
      for (let i = 0; i < this.errorsFile.length; i++) {
        errors.push({
          row: parseInt(this.errorsFile[i]["row"]) + 2,
          "IBAN*":
            this.excelRecords[this.errorsFile[i]["row"]][this.ibanHeader],
          "Amount*":
            this.excelRecords[this.errorsFile[i]["row"]][this.amountHeader],
          "Payment number":
            this.excelRecords[this.errorsFile[i]["row"]][
              this.paymentNumberHeader
            ],
          "Track id":
            this.excelRecords[this.errorsFile[i]["row"]][this.trackIdHeader],
          Description:
            this.excelRecords[this.errorsFile[i]["row"]][
              this.descriptionHeader
            ],
          Comment:
            this.excelRecords[this.errorsFile[i]["row"]][this.commentHeader],
          Source:
            this.excelRecords[this.errorsFile[i]["row"]][this.sourceHeader],
          Account:
            this.excelRecords[this.errorsFile[i]["row"]][this.accountHeader],
          "Reason code":
            this.excelRecords[this.errorsFile[i]["row"]][this.reasonCodeHeader],
          "National code":
            this.excelRecords[this.errorsFile[i]["row"]][
              this.nationalCodeHeader
            ],
          Birthdate:
            this.excelRecords[this.errorsFile[i]["row"]][this.birthdateHeader],

          error: this.errorsFile[i]["description"],
        })
      }

      var ws = XLSX.utils.json_to_sheet(errors, {
        header: [
          "row",
          "IBAN*",
          "Amount*",
          "Payment number",
          "Track id",
          "Description",
          "Comment",
        ],
      })

      XLSX.utils.book_append_sheet(wb, ws, "errors")
      XLSX.writeFile(wb, "errors.xlsx")
    },
    handle_function_call(function_name) {
      this[function_name]()
      this.showErrorsModal = false
    },
    exceedMaxCountError(recordsCount) {
      return {
        title: "تعداد برداشت‌ها بیشتر از حد مجاز است",
        description: `در هر فایل حداکثر، امکان ثبت ${this.maxRecordsCount} برداشت وجود دارد.
        تعداد برداشت‌های شما در این فایل ${recordsCount} است.`,
        primaryBtn: {
          text: "بارگذاری مجدد",
          action: "openUploadWindow",
        },
      }
    },
    switchInfoBoxText(isOn) {
      if (!isOn)
        return "با فعال‌سازی امکان لغو، تا زمان ارسال به بانک، فرصت لغو درخواست خود را خواهید داشت."

      return `شما تا  ${this.timePrediction} می توانید درخواست خود را از طریق «جزییات برداشت گروهی» لغو کنید.`
    },
    cancelTimePrediction() {
      this.$http
        .get(this.$store.getters.apiUrlBatchV2 + "/cancel-time/", {
          timeout: 0,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          const timestamp = response?.data?.time
          this.timePrediction = this.getLocalTime(timestamp)
          this.fetchedCancelTime = true
        })
        .catch(() => {
          //snackbar
        })
    },
    getLocalTime(timestamp) {
      timestamp = parseInt(timestamp) * 1000
      let jMoment = require("moment-jalaali")
      jMoment.loadPersian({ dialect: "persian-modern" })
      return this.jalaliDate(timestamp, "jYYYY/jM/jD - HH:mm")
    },
    onSwitchToggle(event) {
      this.isCancelable = event
    },
    openConfirmModal() {
      this.showConfirmModal = true
    },
    checkForDuplicateBatchAndSend() {
      if (
        this.excelRecords?.length > 0 &&
        this.errorsFile?.length === 0 &&
        this.modalErrors?.length === 0
      ) {
        this.sendingSettlement = true
        const batch_id = this.createBatchId(this.batchSettlementList)

        this.checkBatchForDuplication(batch_id).then((response) => {
          if (response) {
            this.showConfirmModal = false
            this.resetData()
            this.pushErrorAndSetFailed(this.duplicatedBatchId())
            this.showErrorsModal = true
            return
          }

          const finalData = {
            batch_id,
            type: this.settlementMethods === "SMART" ? "AUTO" : "ACH",
            cancelable: this.isCancelable,
            batches_settlement: this.batchSettlementList,
          }
          if (this.isQueued) {
            this.pushToQueuedList(finalData)
          } else this.sendData(finalData)
        })
      }
    },
    createBatchSettlementList() {
      this.batchSettlementList = []

      this.excelRecords.map((item) => {
        const track_id = item[this.trackIdHeader]
          ? item[this.trackIdHeader]?.toString().trim()
          : uuidv4()

        let iban =
          item[this.ibanHeader]
            ?.toString()
            .trim()
            ?.replace(/\r?\n|\r/g, "") ?? null

        const amount = item[this.amountHeader]
        const payment_number = item[this.paymentNumberHeader]
        const description =
          item[this.descriptionHeader]?.toString().trim() ?? null
        const comment = item[this.commentHeader]?.toString().trim() ?? null
        const source =
          item[this.sourceHeader]?.toString().trim().toUpperCase() ?? null
        const account = item[this.accountHeader]?.toString().trim() ?? null

        let reasonCode = item[this.reasonCodeHeader]?.toString().trim() ?? null
        if (reasonCode?.length === 1 && Number(reasonCode) < 10)
          reasonCode = reasonCode.padStart(2, "0")

        let nationalCode =
          item[this.nationalCodeHeader]?.toString().trim() ?? null
        if (nationalCode?.length < 10)
          nationalCode = nationalCode.padStart(10, "0")

        const birthdateCode =
          item[this.birthdateHeader]?.toString().trim() ?? null

        let batchesSettlementObject = {
          track_id,
          iban,
          amount,
          description,
          comment,
          source,
          account,
          reason_code: reasonCode,
          receiver_information: {
            national_code: nationalCode,
            birth_date: birthdateCode,
          },
        }

        if (payment_number !== null && payment_number !== undefined) {
          batchesSettlementObject = {
            ...batchesSettlementObject,
            payment_number,
          }
        } else {
          if ("payment_number" in batchesSettlementObject) {
            delete batchesSettlementObject.payment_number
          }
        }

        if (source === "WALLET") delete batchesSettlementObject.account
        this.batchSettlementList.push(batchesSettlementObject)
      })
    },
    sendData(finalData) {
      this.$http
        .post(
          this.$store.getters.apiUrlBatchV2 + "/batches-settlement",
          finalData,
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then(() => {
          this.sendingSettlement = false
          this.showConfirmModal = false
          this.resetData()
          this.$emit("getBatches")
          this.$emit("closeModal")
        })
        .catch((err) => {
          //callsnackbar
          this.sendingSettlement = false
        })
    },
    pushToQueuedList(finalData) {
      let queuedSettlements = []

      finalData.batches_settlement.forEach((element) => {
        queuedSettlements.push({
          amount: element.amount,
          iban: element.iban,
          payment_number: element.payment_number,
          track_id: element.track_id,
          comment: null,
          description: element.description,
          is_instant: !finalData.cancelable,
          type: finalData.type,
        })
      })

      const storeUrl =
        this.$store.getters.apiWithVersionParam("3") + "/settlement/queued"
      const data = {
        group_track_id: finalData.batch_id,
        settlements: [...queuedSettlements],
      }

      this.$http
        .post(storeUrl, data)
        .then(() => {
          this.sendingSettlement = false
          this.showConfirmModal = false
          this.successfullyQueued = true
        })
        .catch((error) => {
          this.sendingSettlement = false

          if (
            error?.response?.status === 422 &&
            error?.response?.data?.errors?.user_name
          ) {
            this.$store.commit(
              "callsnackbar",
              error.response.data.errors.user_name[0]
            )
          } else {
            this.$emit("errorHappened")
          }
        })
    },
    checkBatchForDuplication(newBatch) {
      const data = {
        batch_id: newBatch,
      }

      return new Promise((resolve) => {
        this.$http
          .post(
            this.$store.getters.apiUrlBatchV2 + "/batches/check-batch-id",
            data,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            resolve(response?.data?.is_already_exists)
          })
          .catch(() => {
            resolve(false)
          })
      })
    },
    ShowQueuedInfoModal() {
      this.$emit("openQueuedInfoModal")
      if (!this.seenQueuedInfo) {
        this.$store.dispatch("saveAction", {
          name: "queued_batch_settlement_onboarding",
        })
      }
    },
    closeModalAndReset() {
      this.resetData()
      this.$emit("closeModal")
    },
    goToQueuedOnDesktop() {
      this.$router.push({
        name: "businessHome",
        params: {
          openQueuedModal: true,
        },
      })
    },
    createBatchId(batchSettlementList) {
      const batches_settlement_stringify = JSON.stringify(batchSettlementList)
      return SHA512(batches_settlement_stringify).toString()
    },
  },
}
</script>

<style lang="scss" scoped>
.dashed-border {
  border-bottom: 1px dashed $van_color05;

  &__white {
    border-bottom: 1px dashed $van_color13;
  }

  &__666 {
    border-bottom: 1px dashed $van_color03;
  }
}

.border-0 {
  border: none;
}
.conditions {
  list-style: inside;
  li {
    &::marker {
      color: $van-color03;
    }
  }
}
.methods-skeleton {
  margin-right: -15px;
}
</style>
