<template>
  <div class="table-filter-container">
    <v-layout
      class="filter_row d-flex justify-start align-center wrap ma-0 pa-0"
    >
      <v-flex class="selectFilter ml-1" style="max-width: 180px">
        <v-select
          v-model="computedAccountFilter"
          :items="accountItems"
          item-text="name"
          item-value="value"
          solo
          flat
          height="40"
          outlined
          label="همه حساب‌ها"
          :menu-props="{ contentClass: 'withActiveStyle' }"
        >
          <template #item="data">
            <v-layout align-center justify-center>
              <v-flex class="font_2"> {{ data.item.name }} </v-flex>
              <v-flex class="checkIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#4C9F87"
                    d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                    style="&#10;"
                  />
                </svg>
              </v-flex>
            </v-layout>
          </template>
        </v-select>
      </v-flex>
      <v-flex class="selectFilter" style="max-width: 180px">
        <v-select
          v-model="computedTransactionType"
          :items="transactionTypesList"
          item-text="name"
          item-value="value"
          solo
          flat
          height="40"
          outlined
          label="همه نوع تراکنش‌ها"
          :menu-props="{ contentClass: 'withActiveStyle' }"
        >
          <template #item="data">
            <v-layout align-center justify-center>
              <v-flex class="font_2">
                {{ data.item.name }}
              </v-flex>
              <v-flex class="checkIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#4C9F87"
                    d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                    style="&#10;"
                  />
                </svg>
              </v-flex>
            </v-layout>
          </template>
        </v-select>
      </v-flex>
      <div class="ml-1" style="max-width: 180px; position: relative">
        <v-text-field
          v-model="computedPriceRangeStatement"
          solo
          flat
          label="بازه مبلغ"
          class="font_4"
          @click="openPriceModal"
          @keydown="openPriceModal"
          @mouseover="showTooltip"
          @mouseleave="isShowPriceTooltip = false"
        >
          <template #label>
            <span class="font-12-no-000-24"> بازه مبلغ </span>
          </template>
        </v-text-field>
        <span
          v-if="isShowPriceTooltip"
          class="tooltip-price-range font-11-no-white-18"
        >
          {{ priceRange }}
        </span>
        <GeneralDialog
          :active="isShowPriceModal"
          title="انتخاب بازه مبلغ"
          btn1-text="انتخاب"
          btn2-text="منصرف شدم"
          content-class="confirmationMessageDialog"
          :width="360"
          :btn1-disabled="isDisableSetPriceBtn"
          @btn1Clicked="setInputtedPrice"
          @btn2Clicked="resetPriceInput"
        >
          <template #extra>
            <div class="price-block">
              <PriceInput @send-up-price="setPriceFromInput" />
              <v-divider class="my-2" style="border-style: dashed"></v-divider>
              <PriceInput title="مبلغ تا:" @send-up-price="setPriceToInput" />
            </div>
            <span v-if="isShowPriceError" class="font_25">
              مقادیر ورودی نادرست است
            </span>
          </template>
        </GeneralDialog>
      </div>
      <div class="ml-1" style="max-width: 180px">
        <v-select
          v-model="computedDuration"
          :items="durationItems"
          item-text="name"
          item-value="value"
          solo
          flat
          height="40"
          outlined
          :menu-props="{ maxHeight: '360px', contentClass: 'withActiveStyle' }"
        >
          <template #selection="{ item }">
            <span
              v-if="
                computedDuration === 'timeRange' &&
                (fromDateText !== null || toDateText !== null)
              "
              class="time-range-selection"
            >
              {{ fromDateText }} {{ toDateText }}
            </span>
            <span v-else> {{ item.name }}</span>
          </template>
          <template #item="data">
            <v-layout @click="openDialog(data.item.value)">
              <v-flex class="font_2">
                {{ data.item.name }}
              </v-flex>
              <v-flex class="checkIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#4C9F87"
                    d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                    style="&#10;"
                  />
                </svg>
              </v-flex>
            </v-layout>
          </template>
        </v-select>
      </div>
      <v-dialog v-model="datePickerStatus" width="360" persistent>
        <DatePickerModal
          v-if="datePickerStatus"
          @send-form="sendDateForm"
          @close-date-picker-modal="cancelSelectingDate"
        />
      </v-dialog>

      <v-flex class="filterBut">
        <v-btn
          text
          dark
          class="ml-0"
          :class="isShakeAdded ? 'btn_small_normal shake' : 'btn_small_normal'"
          :disabled="isDisableSettingFiltersBtn"
          @click="setFilters"
        >
          اعمال فیلتر‌ها
        </v-btn>
      </v-flex>
    </v-layout>
    <v-divider class="mt-3 mb-1"></v-divider>
    <div>
      <span class="font_24">تراکنش‌های: </span>
      <v-chip
        class="ma-2 ml-1"
        :class="transactionSource === 'All' ? 'selected' : 'unselected'"
        outlined
        @click="changeTransactionSource('All')"
      >
        <span class="font-11-bo500-333-17"> بانک </span>
      </v-chip>
      <v-chip
        class="ma-2 mr-1"
        :class="transactionSource === 'Vandar' ? 'selected' : 'unselected'"
        outlined
        @click="changeTransactionSource('Vandar')"
      >
        <span class="font-11-bo500-333-17"> وندار </span>
      </v-chip>
    </div>
  </div>
</template>

<script>
import GeneralDialog from "@/components/modals/generalDialog"
import PriceInput from "./PriceInput.vue"
import DatePickerModal from "@/components/report/modals/DatePickerModal/DatePickerModal.vue"
import { mapActions, mapMutations, mapState } from "vuex"

export default {
  name: "BankInvoiceTableFilter",
  components: {
    GeneralDialog,
    PriceInput,
    DatePickerModal,
  },
  provide: {
    selectedFromDate: this?.wholeFromDate,
    selectedToDate: this?.this.wholeToDate,
    shouldReset: false,
  },
  data() {
    return {
      localAccountFilter: null,
      accountItems: [],
      localTransactionType: null,
      transactionTypesList: [
        {
          name: "همه نوع تراکنش‌ها",
          value: null,
        },
        {
          name: "واریز",
          value: "CREDIT",
        },
        {
          name: "برداشت",
          value: "DEBIT",
        },
      ],
      isShowPriceModal: false,
      priceRangeStatement: "",
      priceRange: "",
      formattedPriceFrom: null,
      formattedPriceTo: null,
      isShowPriceTooltip: false,
      isDisableSetPriceBtn: true,
      isShowPriceError: false,
      durationItems: [
        {
          name: "از ابتدای ثبت‌نام تاکنون",
          value: "",
        },
        {
          name: "دیروز",
          value: "YESTERDAY",
        },
        {
          name: "روز جاری",
          value: "DAY",
        },
        {
          name: "هفته جاری",
          value: "WEEK",
        },
        {
          name: "ماه جاری",
          value: "MONTH",
        },
        {
          name: "سال جاری",
          value: "YEAR",
        },
        {
          name: "بازه زمانی دلخواه",
          value: "timeRange",
        },
      ],
      localDuration: "",
      fromDateText: null,
      toDateText: null,
      datePickerStatus: false,
      isShakeAdded: false,
      isDisableSettingFiltersBtn: true,
      timeoutId: null,
    }
  },
  computed: {
    ...mapState("bankInvoice", [
      "priceFrom",
      "priceTo",
      "fromDate",
      "toDate",
      "areFiltersApplied",
      "transactionSource",
    ]),
    accountsList() {
      return this.$store.state.accountsList
    },
    computedAccountFilter: {
      get() {
        return this.localAccountFilter
      },
      set(val) {
        this.doClearTimeout()
        this.localAccountFilter = val
        this.addAndDisableShaking()
        this.enableOrDisableSettingFilterBtn(
          this.enablingConditionOfSettingFiltersBtn
        )
        this.setAccountFilter(val)
      },
    },
    computedTransactionType: {
      get() {
        return this.localTransactionType
      },
      set(val) {
        this.localTransactionType = val
        this.addAndDisableShaking()
        this.enableOrDisableSettingFilterBtn(
          this.enablingConditionOfSettingFiltersBtn
        )
        this.setTransactionType(val)
      },
    },
    computedPriceRangeStatement: {
      get() {
        return this.priceRangeStatement
      },
      set(val) {
        this.priceRangeStatement = val
        this.addAndDisableShaking()
        this.enableOrDisableSettingFilterBtn(
          this.enablingConditionOfSettingFiltersBtn
        )
        this.setPriceRangeStatement(val)
      },
    },
    computedDuration: {
      get() {
        return this.localDuration
      },
      set(val) {
        this.localDuration = val
        this.addAndDisableShaking()
        this.setDuration(val)
        if (val !== "timeRange") {
          this.enableOrDisableSettingFilterBtn(
            this.enablingConditionOfSettingFiltersBtn
          )
        }
      },
    },
    enablingConditionOfSettingFiltersBtn() {
      return (
        this.computedAccountFilter ||
        this.computedTransactionType ||
        this.computedPriceRangeStatement ||
        (this.computedDuration !== "timeRange"
          ? this.computedDuration
          : this.fromDate || this.toDate)
      )
    },
  },
  watch: {
    accountsList() {
      this.setAccountsTypeList()
    },
    fromDate() {
      this.enableOrDisableSettingFilterBtn(
        this.enablingConditionOfSettingFiltersBtn
      )
    },
    toDate() {
      this.enableOrDisableSettingFilterBtn(
        this.enablingConditionOfSettingFiltersBtn
      )
    },
    areFiltersApplied(val) {
      if (!val) this.clearAllAppliedFilters()
    },
  },
  beforeDestroy() {
    this.doClearTimeout()
  },
  mounted() {
    this.setAccountsTypeList()
  },
  methods: {
    ...mapMutations("bankInvoice", [
      "toggleResetPriceInput",
      "setAccountFilter",
      "setTransactionType",
      "setPriceRangeStatement",
      "setPriceFrom",
      "setPriceTo",
      "setDuration",
      "setFromDate",
      "setToDate",
      "setTableFilters",
      "toggleShouldGetData",
      "specifyTransactionSource",
      "toggleShouldGetData",
    ]),
    ...mapActions("bankInvoice", ["setIsRealtimeStatement"]),
    setAccountsTypeList() {
      let accountsList = this.$store.state.accountsList
      let arr = []
      if (this.$store.state.accountsList.length > 0) {
        arr.push({ name: "همه حساب‌ها", value: "" })
        accountsList.forEach((accountItem) =>
          arr.push({
            name: `${accountItem.name} | ${accountItem.account}`,
            value: accountItem.iban,
          })
        )
      }
      this.accountItems = arr
    },
    openPriceModal() {
      this.isShowPriceModal = true
      this.toggleResetPriceInput(false)
    },
    closePriceModal() {
      this.isShowPriceModal = false
    },
    resetPriceInput() {
      this.closePriceModal()
      this.toggleResetPriceInput(true)
      this.priceRange = ""
      this.computedPriceRangeStatement = ""
      this.setPriceFromInput(null)
      this.setPriceToInput(null)
    },
    setPriceFromInput(priceObj) {
      const priceIncludeZero =
        priceObj?.price === 0 || priceObj?.price ? priceObj?.price : null
      this.formattedPriceFrom =
        priceObj?.formattedPrice === "0" || priceObj?.formattedPrice
          ? priceObj?.formattedPrice
          : null

      this.setPriceFrom(priceIncludeZero)
      this.checkCorrectionOfInputtedPrice()
    },
    setPriceToInput(priceObj) {
      const priceIncludeZero =
        priceObj?.price === 0 || priceObj?.price ? priceObj?.price : null
      this.formattedPriceTo =
        priceObj?.formattedPrice === "0" || priceObj?.formattedPrice
          ? priceObj?.formattedPrice
          : null

      this.setPriceTo(priceIncludeZero)
      this.checkCorrectionOfInputtedPrice()
    },
    setInputtedPrice() {
      this.priceRange =
        "از " +
        this.formattedPriceFrom +
        " " +
        this.currencyName +
        " تا " +
        this.formattedPriceTo +
        " " +
        this.currencyName

      this.computedPriceRangeStatement = "بازه مبلغ (" + this.priceRange + ")"

      this.closePriceModal()
      this.toggleResetPriceInput(false)
    },
    showTooltip() {
      if (this.computedPriceRangeStatement) this.isShowPriceTooltip = true
    },
    checkCorrectionOfInputtedPrice() {
      if (this.priceFrom !== null && this.priceTo !== null) {
        if (this.priceFrom >= this.priceTo) {
          this.togglePriceError(true)
          this.toggleDisableSettingPriceBtn(true)
        } else {
          this.togglePriceError(false)
          this.toggleDisableSettingPriceBtn(false)
        }
      } else {
        this.toggleDisableSettingPriceBtn(true)
      }
    },
    openDialog(value) {
      this.computedDuration = value

      if (value === "timeRange") {
        this.datePickerStatus = true
      } else {
        this.toDateText = null
        this.fromDateText = null
        this.setFromDate(null)
        this.setToDate(null)
      }
    },
    sendDateForm(payload) {
      this.datePickerStatus = false
      this.fromDateText = payload.fromDateText
      this.toDateText = payload.toDateText

      this.setFromDate(payload.fromDate)
      this.setToDate(payload.toDate)

      this._provided.selectedFromDate = payload.wholeFromDate
      this._provided.selectedToDate = payload.wholeToDate
      this._provided.shouldReset = false
    },
    cancelSelectingDate() {
      this.datePickerStatus = false
      this._provided.shouldReset = true
      this._provided.selectedFromDate = ""
      this._provided.selectedToDate = ""
      this.toDateText = null
      this.fromDateText = null
      this.setFromDate(null)
      this.setToDate(null)
    },
    setFilters() {
      this.setIsRealtimeStatement(false)
      this.setTableFilters(true)
      this.toggleShouldGetData(true)
    },
    togglePriceError(status) {
      this.isShowPriceError = status
    },
    toggleDisableSettingPriceBtn(status) {
      this.isDisableSetPriceBtn = status
    },
    addShake() {
      this.isShakeAdded = true
    },
    disableShake() {
      this.isShakeAdded = false
    },
    doClearTimeout() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
      }
    },
    enableSettingFiltersBtn() {
      this.isDisableSettingFiltersBtn = false
    },
    disableSettingFiltersBtn() {
      this.isDisableSettingFiltersBtn = true
    },
    addAndDisableShaking() {
      this.addShake()
      this.timeoutId = setTimeout(() => this.disableShake(), 300)
    },
    enableOrDisableSettingFilterBtn(enableCondition) {
      if (enableCondition) {
        this.enableSettingFiltersBtn()
      } else {
        this.disableSettingFiltersBtn()
      }
    },
    clearAllAppliedFilters() {
      this.computedAccountFilter = null
      this.computedTransactionType = null
      this.computedDuration = ""
      this.resetPriceInput()
      this.cancelSelectingDate()
    },
    changeTransactionSource(transactionSource) {
      this.specifyTransactionSource(transactionSource)
      this.toggleShouldGetData(true)
    },
  },
}
</script>

<style lang="scss" scoped>
.table-filter-container {
  background-color: #eee;
  margin: 5px 0 20px 0;
  padding: 17px 20px 4px 15px;
}

.table-filter-row {
  background: #f1f1f1;
  padding: 16px 24px;
  border-radius: 4px;
}

.price-block {
  background: #f5f5f5;
  border-radius: 4px;
  width: 300px;
  height: 136px;
  padding: 20px;
}

.tooltip-price-range {
  background: $van_color03;
  padding: 4px 8px;
  border-radius: 4px;
  position: absolute;
  top: -30px;
  transition: all 2s ease-in-out 1s;
}

.time-range-selection {
  font-size: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 120px;
  overflow: hidden;
}

.v-chip.v-chip--outlined.v-chip.v-chip {
  height: 24px !important;
}

.v-chip.v-chip--outlined.v-chip.v-chip.selected {
  background: #e1e9e6 !important;
  border: 1px solid $van_green !important;
}

.v-chip.v-chip--outlined.v-chip.v-chip.unselected {
  background: $van_color06 !important;
  border: 1px solid $van_color03 !important;
}
</style>
