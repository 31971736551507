<template>
  <v-layout column wrap justify-center align-start>
    <v-flex style="width: 100%">
      <v-layout
        justify-space-between
        :align-start="!$vuetify.breakpoint.xsOnly"
        :column="$vuetify.breakpoint.xsOnly"
      >
        <v-flex class="d-flex align-start">
          <div class="font_1 mr-0 ml-auto">برداشت گروهی</div>
          <v-btn
            text
            depressed
            dark
            class="btn_small_normal ml-0 mt-0"
            :class="{ 'd-none': $vuetify.breakpoint.smAndUp }"
            @click="uploadBtnClicked"
          >
            بارگذاری فایل
          </v-btn>
        </v-flex>
        <v-spacer :class="{ 'd-none': $vuetify.breakpoint.xsOnly }" />
        <v-flex
          class="align-start"
          :class="{
            'd-flex justify-end': $vuetify.breakpoint.smAndUp,
          }"
        >
          <v-btn
            text
            depressed
            outlined
            class="btn_small_normal ml-0 transparent mt-0"
            :class="{ 'mr-0': $vuetify.breakpoint.xsOnly }"
            @click="help = true"
          >
            راهنما و فایل نمونه
          </v-btn>
          <v-btn
            text
            depressed
            dark
            class="btn_small_normal ml-0 mt-0"
            :class="{ 'd-none': $vuetify.breakpoint.xsOnly }"
            @click="uploadBtnClicked"
          >
            بارگذاری فایل
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex style="width: 100%">
      <!-- skeleton -->
      <div v-if="pageLoading">
        <div v-for="item in 5" :key="item">
          <card skeleton />
        </div>
      </div>
      <!-- data table -->
      <div v-else>
        <empty-batches-settlement v-if="emptyBatches" />
        <div v-else>
          <div v-for="item in batches" :key="item.batch_id">
            <card
              :batch-item="item"
              @viewBatchDetail="viewDetail"
              @exportBatch="exportBatch"
            />
          </div>

          <!-- load more -->
          <observer v-if="!endOfLoadMore" @intersect="getBatches('loadMore')" />

          <!-- skeleton -->
          <div v-if="moreSkeletonLoading">
            <div v-for="item in 3" :key="item">
              <card skeleton />
            </div>
          </div>
        </div>
      </div>
    </v-flex>

    <!-- dialogs -->
    <!-- guideline modal -->
    <v-dialog
      v-model="help"
      content-class="left-modal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <guideline-modal
        :max-records-count="maxRecordsCount"
        :min-amount-each-record="minAmountEachRecord"
        :sample-file-cdn="sampleFileCDN"
        @closeModal="closeModal()"
      ></guideline-modal
    ></v-dialog>

    <v-dialog
      v-model="showSmartMethodModal"
      content-class="left-modal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <smart-settlement-guide
        @closeModal="showSmartMethodModal = false"
      ></smart-settlement-guide
    ></v-dialog>

    <!-- upload modal -->
    <v-dialog
      v-model="showUploadModal"
      :content-class="
        showSmartMethodModal || showQueuedInfoModal
          ? 'left-modal secondModal'
          : 'left-modal'
      "
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <UploadModal
        :max-records-count="maxRecordsCount"
        :sample-file-cdn="sampleFileCDN"
        :min-amount-each-record="minAmountEachRecord"
        @closeModal="closeModal()"
        @openSmartModal="showSmartMethodModal = true"
        @openQueuedInfoModal="showQueuedInfoModal = true"
        @getBatches="getBatches()"
      />
    </v-dialog>

    <v-dialog
      v-model="showQueuedInfoModal"
      content-class="left-modal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <queue-details @closeModal="showQueuedInfoModal = false" />
    </v-dialog>

    <!-- van modal -->
    <v-dialog
      v-model="showDetails"
      :content-class="
        showSettlementDetails ? 'secondModal left-modal' : 'left-modal'
      "
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <SettlementDetails
        v-if="showDetails"
        :selected-settlement="selectedSettlement"
        :loading="exportBtnLoading"
        @exportBatch="exportBatch"
        @showOneTransactionDetails="showOneTransactionDetails"
        @closeModal="closeModal()"
        @reloadBatchList="getBatches()"
      />
    </v-dialog>

    <!-- show details one transaction -->
    <v-dialog
      v-model="showSettlementDetails"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <show
        v-if="showSettlementDetails"
        :item="transactionDetails"
        @closeModal="closeSecondModal()"
      />
    </v-dialog>
  </v-layout>
</template>

<script>
import emptyBatchesSettlement from "../elements/emptyBatchesSettlement.vue"
import card from "../elements/card.vue"
import SettlementDetails from "../modals/settlementDetails.vue"
import show from "@/components/report/modals/show.vue"
import observer from "../../elements/observer.vue"
import smartSettlementGuide from "../modals/smartSettlementGuide.vue"
import XLSX from "xlsx"
import { numbersHelper } from "@/mixins/numbersHelper"
import guidelineModal from "../modals/guidelineModal"
import UploadModal from "../modals/uploadModal"
import QueueDetails from "@/components/A2A/modals/queueDetails.vue"

export default {
  name: "BatchesSettlement",
  components: {
    emptyBatchesSettlement,
    card,
    SettlementDetails,
    show,
    observer,
    guidelineModal,
    UploadModal,
    smartSettlementGuide,
    QueueDetails,
  },
  mixins: [numbersHelper],
  data() {
    return {
      page: 1,
      totalPage: 1,
      pageLoading: false,
      moreSkeletonLoading: false,
      endOfLoadMore: false,
      batches: null,
      data: null,
      help: false,
      showDetails: false,
      selectedSettlement: null,
      showSettlementDetails: false,
      transactionDetails: null,
      exportBtnLoading: false,
      showUploadModal: false,
      minAmountEachRecord: 5000, //tooman
      maxRecordsCount: 1000,
      showSmartMethodModal: false,
      showQueuedInfoModal: false,
      sampleFileCDN:
        "https://cdn.vandar.io/public/files/sample-batch-settlement.xlsx",
    }
  },
  computed: {
    emptyBatches() {
      return this.batches == null || this.batches.length == 0
    },
  },
  mounted() {
    this.getBatches()
    this.$store.dispatch("setBusinessCeilingAmount")
  },
  methods: {
    closeSecondModal() {
      this.showSettlementDetails = false
      this.transactionDetails = null
    },
    showOneTransactionDetails(transactionId) {
      this.transactionDetails = null
      this.$store.commit("changeShowSettlementLoading", true)
      this.$http
        .get(
          this.$store.getters.apiWithVersionParam("3") +
            "/transaction?id=" +
            transactionId,
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.data.length > 0) {
            this.transactionDetails = response.data.data[0]
            this.showSettlementDetails = true
            this.$store.commit("changeShowSettlementLoading", false)
          }
        })
        .catch(() => {
          this.$store.commit("changeShowSettlementLoading", false)
        })
    },
    exportBatch(batchId) {
      this.exportBtnLoading = true
      this.$http
        .get(
          this.$store.getters.apiUrlBatch +
            "/batch-settlements/" +
            batchId +
            "?per_page=1000",
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          const clonedData = structuredClone(response.data.data)
          let wb = XLSX.utils.book_new()
          clonedData.map((item) => {
            item.receiver_information_birth_date =
              item.receiver_information.birth_date

            item.receiver_information_national_code =
              item.receiver_information.national_code

            delete item.receiver_information

            return item
          })
          let ws = XLSX.utils.json_to_sheet(clonedData, {
            header: [
              "id",
              "track_id",
              "payment_number",
              "amount",
              "iban",
              "iban_owner_name",
              "status",
              "transaction_id",
              "error_message",
              "source",
              "account",
              "reason_code",
              "receiver_information_national_code",
              "receiver_information_birth_date",
            ],
          })

          XLSX.utils.book_append_sheet(wb, ws, "batches_settlement")
          XLSX.writeFile(wb, "batches_settlement.xlsx")
          this.exportBtnLoading = false
        })
        .catch((error) => {
          //callsnackbar
          this.exportBtnLoading = false
        })
    },
    closeModal() {
      this.showUploadModal = false
      this.help = false
      this.showDetails = false
      this.selectedSettlement = null
      this.showSettlementDetails = false
    },
    viewDetail(selectedSettlement) {
      this.selectedSettlement = selectedSettlement
      this.showDetails = true
    },
    uploadBtnClicked() {
      this.showUploadModal = true
    },
    getBatches(kind) {
      if (kind == "loadMore") {
        this.page += 1
        this.moreSkeletonLoading = true
      } else {
        this.page = 1
        this.pageLoading = true
        this.endOfLoadMore = false
      }

      this.$http
        .get(this.$store.getters.apiUrlBatch + "/batches/?page=" + this.page, {
          timeout: 0,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response.data.data !== null) {
            if (kind == "loadMore") {
              let temporaryList = this.batches

              response.data.data.forEach((element) => {
                temporaryList.push(element)
              })

              this.batches = temporaryList
            } else {
              this.batches = response.data.data
            }

            this.page = response.data.meta.current_page
            if (
              response.data.meta.last_page == response.data.meta.current_page
            ) {
              this.endOfLoadMore = true
            }
            this.pageLoading = false
            this.moreSkeletonLoading = false
          }
        })
        .catch((error) => {
          //callsnackbar
          this.pageLoading = false
          this.moreSkeletonLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.right {
  text-align: right;
}
.left {
  text-align: left;
}
</style>
