<template>
  <TicketsLeftModal toolbar-name="تیکت جدید" @closeModal="$emit('closeModal')">
    <div slot="toolbarItems">
      <v-btn
        :disabled="!hasChange"
        class="btn_small_normal"
        style="margin-left: 0"
        dark
        text
        @click="sendTicket"
      >
        ارسال تیکت
      </v-btn>
    </div>
    <v-form ref="form">
      <GeneralCard
        card-title="برای دریافت پاسخ و راهنمایی مناسب توسط پشتیبان، جزییات موضوع را شرح دهید."
      >
        <v-layout justify-start align-start :column="isMobile">
          <v-flex class="label">بخش <span class="error-text">*</span></v-flex>
          <v-flex class="withlabel">
            <v-select
              v-model="selectedCategoryItemComputed"
              :items="categoryItems"
              label="انتخاب کنید"
              return-object
              item-value="id"
              item-text="title"
              no-data-text=""
              outlined
              dense
            ></v-select>
          </v-flex>
        </v-layout>

        <v-layout
          v-show="selectedCategoryItemComputed"
          :column="isMobile"
          justify-start
          align-start
          class="mt-4"
        >
          <v-flex class="label">موضوع <span class="error-text">*</span></v-flex>
          <v-flex class="withlabel">
            <v-select
              v-model="selectedSubjectItem"
              :items="subCategories"
              label="انتخاب کنید"
              return-object
              item-value="id"
              no-data-text=""
              item-text="title"
              outlined
              dense
            >
            </v-select>
          </v-flex>
        </v-layout>
        <v-layout
          v-show="selectedSubjectItem"
          :column="isMobile"
          justify-start
          align-start
          class="mt-4"
        >
          <v-flex class="label">
            عنوان <span class="error-text">*</span>
          </v-flex>
          <v-flex class="withlabel big">
            <v-text-field
              v-model="title"
              :class="{ 'error-state': !isTitleValid }"
              :error-messages="
                isTitleValid ? [] : '<span>وارد کردن عنوان الزامیست</span>'
              "
              outlined
              dense
              maxlength="50"
              counter="50"
              @blur="isTitleFocused = false"
              @focus="isTitleFocused = true"
            >
              <template #message="message">
                <div v-html="message.message" />
              </template>
            </v-text-field>
          </v-flex>
        </v-layout>
        <v-layout
          v-show="selectedSubjectItem"
          :column="isMobile"
          justify-start
          align-start
          class="mt-4"
        >
          <v-flex class="label">
            شرح موضوع
            <span class="error-text">*</span>
          </v-flex>
          <v-flex class="withlabel big">
            <v-textarea
              v-model="description"
              :class="{ 'error-state': !isDescriptionValid }"
              :error-messages="
                isDescriptionValid
                  ? []
                  : '<span>وارد کردن شرح موضوع الزامیست</span>'
              "
              outlined
              dense
              @blur="isDescriptionFocused = false"
              @focus="isDescriptionFocused = true"
            >
              <template #message="message">
                <div v-html="message.message" />
              </template>
            </v-textarea>
          </v-flex>
        </v-layout>
        <v-layout
          v-show="selectedSubjectItem"
          :column="isMobile"
          class="mt-4"
          justify-start
          align-start
        >
          <v-flex class="label"> فایل پیوست</v-flex>
          <v-flex>
            <FileUploader
              :form-id="formId"
              accept=".png,.jpg,.jpeg,.pdf,.xlsx,.xls,.csv"
            ></FileUploader>
          </v-flex>
        </v-layout>
      </GeneralCard>
      <v-layout v-if="getHoursOfNow >= 16" class="work-hour-wrapper">
        <v-flex class="d-flex">
          <span class="info-svg">
            <InfoSvg width="16" height="16" />
          </span>
          <span class="work-hour-info-text">
            با توجه به پایان ساعت پشتیبانی وندار، تیکت شما در اولین ساعات کاری
            پاسخ داده خواهد شد.
          </span>
        </v-flex>
      </v-layout>
    </v-form>
  </TicketsLeftModal>
</template>

<script>
import TicketsLeftModal from "@/components/tickets/components/TicketsLeftModal.vue"
import GeneralCard from "@/components/elements/generalCard"
import { v4 as uuidv4 } from "uuid"
import FileUploader from "@/components/file-uploader/FileUploader.vue"
import InfoSvg from "@/components/tickets/components/InfoSvg.vue"
import servicesRepository from "@/services/servicesRepository"
import { mapState } from "vuex"

export default {
  name: "CreateTicket",
  components: {
    FileUploader,
    TicketsLeftModal,
    GeneralCard,
    // CloudUploadSvg,
    InfoSvg,
  },
  data() {
    return {
      formId: uuidv4(),
      hasChange: false,
      categoryItems: [],
      selectedCategoryItem: "",
      subCategories: [],
      selectedSubjectItem: "",
      title: "",
      description: "",
      attachmentFile: [],
      titleRule: [() => !!this.title],
      isTitleValid: true,
      isTitleFocused: false,
      descriptionRule: [() => !!this.description],
      isDescriptionValid: true,
      isDescriptionFocused: false,
    }
  },
  computed: {
    ...mapState("ticket", ["shouldSetAccountantCategory"]),
    formIsValid() {
      return this.title && this.description
        ? this.isTitleValid && this.isDescriptionValid
        : false
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 600
    },
    getHoursOfNow() {
      return new Date().getHours()
    },
    selectedCategoryItemComputed: {
      get() {
        return this.selectedCategoryItem
      },
      set(value) {
        this.selectedCategoryItem = value
        this.subCategories = [...value.subCategories]
      },
    },
  },
  watch: {
    isTitleFocused(val) {
      if (!val) {
        this.isTitleValid = !!this.title
      }
    },
    isDescriptionFocused(val) {
      if (!val) {
        this.isDescriptionValid = !!this.description
      }
    },
    title(val) {
      this.isTitleValid = !!val
    },
    description(val) {
      this.isDescriptionValid = !!val
    },
    formIsValid(val) {
      this.hasChange = !!val
    },
  },
  mounted() {
    this.getCategories()
  },
  methods: {
    async sendTicket() {
      const url =
        this.$store.state.ticket_api_base_url +
        "/business/" +
        this.$store.state.business.business_name +
        "/tickets"

      // if (this.attachmentFile.length) {
      //   this.attachmentFile.forEach((item) =>
      //     formData.append("attachments[]", item)
      //   )
      // }
      const payload = {
        form_id: this.formId,
        title: this.title,
        message: this.description,
        category_id: this.selectedSubjectItem.id,
      }

      const res = await this.$http.post(url, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      this.$emit("closeModal", res.data.data.ticket)
    },
    async getCategories() {
      const response = await servicesRepository(
        this
      ).ticketServices.httpGetCategories()
      const {
        data: { data },
      } = response

      this.categoryItems = data
      this.presetAccountantCategoryAndSubCategoryAndTitle()
    },
    presetAccountantCategoryAndSubCategoryAndTitle() {
      if (this.shouldSetAccountantCategory) {
        this.selectedCategoryItemComputed = this.categoryItems.find(
          (item) => item.title === "گزارش‌های مالی"
        )

        this.selectedSubjectItem = this.subCategories.find(
          (item) => item.title === "فاکتورها"
        )

        this.title = "مغایرت در فاکتور"
      }
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(.v-label--active) {
  display: none;
}

:deep(.v-label) {
  color: #666666 !important;
  font-size: 12px;
}

:deep(.theme--light.v-input textarea) {
  color: #666666 !important;
}

:deep(.theme--light.v-input input) {
  color: #666666 !important;
}

:deep(.v-select__selection--comma) {
  color: #666666 !important;
}

:deep(.v-input__prepend-outer) {
  display: none !important;
}

:deep(.v-file-input.v-text-field--outlined .v-text-field__slot) {
  cursor: pointer;
}

:deep(.v-input__prepend-inner) {
  padding-top: 2px;
  margin-left: 4px !important;
}

:deep(.error--text) {
  color: #ff5252 !important;
}

.error-state {
  :deep(.v-messages__message) {
    color: #ff5252 !important;
  }
}

.work-hour-wrapper {
  background: #ffffff;
  border-radius: 4px;
  padding: 15px 16px;
}
.info-svg {
  display: flex;
  align-items: center;
}
.work-hour-info-text {
  font-size: 11px;
  line-height: 17px;
  color: #333333;
  margin-right: 10px;
}
</style>
