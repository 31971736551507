<template>
  <v-card class="singleBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">اصلاح شماره ثبت شرکت</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          ref="btn"
          text
          dark
          :loading="loading"
          :disabled="!valid"
          class="btn_small_normal"
          @click="sendForm"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column justify-space-between>
      <v-flex>
        <v-form ref="form" v-model="valid">
          <div class="font_2">شماره ثبت شده معتبر نیست، لطفا اصلاح کنید.</div>
          <v-divider class="mb-7" style="border-style: dashed" />
          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <input-container label="شماره ثبت شرکت" :optional="false">
              <template>
                <v-flex class="withlabel big">
                  <v-text-field
                    ref="businessRegistrationNo"
                    v-model="businessRegistrationNo"
                    solo
                    flat
                    height="40"
                    outlined
                    :rules="businessRegistrationNoRules"
                  >
                    <template #message="message">
                      <div v-html="message.message"></div>
                    </template>
                  </v-text-field>
                </v-flex>
              </template>
            </input-container>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import inputContainer from "../elements/inputContainer.vue"

export default {
  name: "EditRegisterCode",
  components: {
    inputContainer,
  },
  props: {},
  data: function () {
    return {
      valid: false,
      loading: false,
      businessRegistrationNo: null,
      businessRegistrationNoRules: [
        (v) => !!v || "<span> وارد کردن شماره ثبت شرکت الزامی است</span>",
      ],
    }
  },
  watch: {
    businessRegistrationNo: function (newValue) {
      if (newValue) {
        this.businessRegistrationNo = this.convertNumbers2English(
          newValue
        ).replace(/[^0-9]/g, "")
        this.businessRegistrationNo = this.businessRegistrationNo.slice(0, 50)
      }
      this.$refs.businessRegistrationNo.lazyValue = this.businessRegistrationNo
    },
  },
  methods: {
    sendForm() {
      this.loading = true

      var data = {
        wage: this.$store.state.ipg.wage,
        url: this.$store.state.ipg.url,
        urls: this.$store.state.ipg.urls,
        avatar: this.$store.state.business.avatar,
        tax_code: this.$store.state.business.tax_code,
        register_number: this.businessRegistrationNo, //new field
      }
      this.$http
        .post(this.$store.getters.apiUrlParam + "/ipg/update", data, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.$store.commit(
            "callSuccessSnackbar",
            "شماره ثبت شرکت با موفقیت ذخیره شد"
          )
          // this.$store.commit('updateUserOrBusinessValue', {
          //   key: 'register_number',
          //   value: this.businessRegistrationNo,
          // })
          if (!this.$store.state.isUser) {
            this.$store
              .dispatch(
                "retrieveBusinessObject",
                this.$store.state.business.business_name
              )
              .then((response) => {
                //DO Nothing
              })
              .catch((error) => {
                //callsnackbar
              })
          }
          this.loading = false
          this.$emit("closeModal")
        })
        .catch((error) => {
          //callsnackbar
          this.loading = false
        })
    },
  },
}
</script>
