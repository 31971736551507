<template>
  <div style="position: relative">
    <v-data-table
      v-if="isShowSkeleton"
      class="elevation-1 vandarTable"
      :class="$vuetify.breakpoint.smAndDown ? 'smAndDownTable' : 'reportTable'"
      :headers="bankInvoiceTableHeaders"
      :items="skeletonItemsList"
      :items-per-page="20"
      :server-items-length="totalBankInvoiceCount"
      hide-default-footer
      no-data-text="تراکنشی برای نمایش وجود ندارد"
    >
      <template #item>
        <SkeletonDataTable />
      </template>
    </v-data-table>
    <v-data-table
      v-else
      class="elevation-1 vandarTable"
      :class="{ smAndDownTable: $vuetify.breakpoint.smAndDown }"
      :hide-default-header="bankInvoiceItems.length === 0"
      :headers="bankInvoiceTableHeaders"
      :items="bankInvoiceItems"
      :server-items-length="bankInvoiceItems.length"
      :items-per-page="itemsPerPage"
      hide-default-footer
      no-data-text="تراکنشی برای نمایش وجود ندارد"
    >
      <template #item="{ item }">
        <BankInvoiceTableRow :bank-invoice-item="item" />
      </template>
    </v-data-table>

    <observer v-if="hasMoreItems" @intersect="getBankInvoicesDataByScrolling" />
    <v-data-table
      v-if="isLoading"
      class="elevation-1 vandarTable"
      :class="$vuetify.breakpoint.smAndDown ? 'smAndDownTable' : 'reportTable'"
      :items="[1, 2, 3, 4, 5]"
      :headers="bankInvoiceTableHeaders"
      hide-default-header
      hide-default-footer
    >
      <template #item>
        <SkeletonDataTable :should-set-width="true" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import SkeletonDataTable from "@/components/BankInvoice/SkeletonDataTable.vue"
import BankInvoiceTableRow from "@/components/BankInvoice/TableRow.vue"
import servicesRepository from "@/services/servicesRepository"
import { mapState, mapMutations } from "vuex"
import observer from "@/components/elements/observer.vue"

export default {
  name: "DataTable",
  components: {
    BankInvoiceTableRow,
    SkeletonDataTable,
    observer,
  },
  data() {
    return {
      skeletonItemsList: [1, 2, 3, 4, 5, 6, 7, 8],
      totalBankInvoiceCount: 1,
      page: 1,
      itemsPerPage: 20,
      hasMoreItems: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapState("bankInvoice", [
      "isSearchApplied",
      "shouldGetData",
      "bankInvoiceItems",
      "isShowSkeleton",
      "bankInvoiceUrl",
      "transactionSource",
      "isRealtimeStatement",
    ]),
    bankInvoiceTableHeaders() {
      return [
        {
          text: "ردیف",
          align: "center",
          sortable: false,
          value: "status",
          width: "4%",
        },
        {
          text: "نوع تراکنش",
          align: "right",
          sortable: false,
          value: "status",
        },
        {
          text: "مبلغ (" + this.currencyName + ")",
          align: "right",
          sortable: false,
          value: "amount",
        },
        {
          text: "حساب مبدا",
          align: "right",
          sortable: false,
          value: "status",
        },
        {
          text: "تاریخ و ساعت",
          align: "right",
          sortable: false,
          value: "effective_at_jalali",
        },
        {
          text: "شرح",
          align: "right",
          sortable: false,
          value: "payer",
          width: "37%",
        },
        {
          text: "مانده (" + this.currencyName + ")",
          align: "right",
          sortable: false,
          value: "wallet",
        },
      ]
    },
  },
  watch: {
    shouldGetData(val) {
      if (val) {
        this.resetPage()
        this.resetBankInvoiceItems()
        if (this.page === 1) {
          this.getBankInvoices()
        }
      }
    },
  },
  mounted() {
    this.getBankInvoices()
  },
  methods: {
    ...mapMutations("bankInvoice", [
      "setTableFilters",
      "toggleSearchAppliedStatus",
      "toggleShouldGetData",
      "setBankInvoiceItems",
      "toggleSkeletonStatus",
      "resetBankInvoiceItems",
      "specifyBankInvoiceUrl",
    ]),
    async getBankInvoices() {
      try {
        this.specifyBankInvoiceUrl({
          page: this.page,
          per_page: this.itemsPerPage,
          currencyName: this.currencyName,
          transactionSource: this.transactionSource,
        })
        this.hasMoreItems = false

        if (this.bankInvoiceItems.length === 0) this.toggleSkeletonStatus(true)
        else this.isLoading = true
        let response
        if (this.isRealtimeStatement) {
          response = await servicesRepository(
            this
          ).bankInvoiceServices.httpGetAccountRealtimeStatement(
            this.bankInvoiceUrl
          )
        } else {
          response = await servicesRepository(
            this
          ).bankInvoiceServices.httpGetBankInvoices(this.bankInvoiceUrl)
        }

        this.hasMoreItems = response.data.data.has_more

        const payload = {
          bankInvoiceItems: response.data.data.invoices,
          page: this.page,
          itemsPerPage: this.itemsPerPage,
        }
        this.setBankInvoiceItems(payload)
      } finally {
        this.toggleShouldGetData(false)
        this.toggleSkeletonStatus(false)
        this.isLoading = false
      }
    },
    getBankInvoicesDataByScrolling() {
      this.page++
      this.getBankInvoices()
    },
    resetPage() {
      this.page = 1
    },
  },
}
</script>

<style scoped lang="scss"></style>
