<script>
import DatePicker from "@/components/modals/datePicker.vue"
import InputContainer from "@/components/elements/inputContainer.vue"
import screenSize from "@/mixins/screenSize.js"

export default {
  name: "BirthdateField",
  components: { InputContainer, DatePicker },
  mixins: [screenSize],
  data() {
    return {
      registerDateText: "",
      registerDate: "",
    }
  },
  methods: {
    setTheDate(dateObject) {
      this.registerDateText = dateObject.dateText
      this.registerDate = dateObject.date
      this.$emit("send-up-date", dateObject.date)
    },
  },
}
</script>

<template>
  <v-layout
    class="mt-3 pa-0 justify-space-between"
    :class="{ 'd-flex flex-column': reuseIsMobile }"
  >
    <v-flex class="label"> تاریخ تولد </v-flex>
    <v-flex class="flex-column withlabel big">
      <InputContainer class="custom-input" :optional="true">
        <v-flex class="withlabel big">
          <v-text-field
            v-model.trim="registerDateText"
            solo
            flat
            height="40"
            outlined
            @focus="$store.commit('setShowStateOfDatePicker', true)"
            @keypress="(e) => e.preventDefault()"
          >
            <template #message="message">
              <div v-html="message.message"></div>
            </template>
          </v-text-field>
        </v-flex>
      </InputContainer>
      <DatePicker :max-date-is-today="true" @setDate="setTheDate($event)" />
    </v-flex>
  </v-layout>
</template>

<style scoped lang="scss"></style>
