<script>
import screenSize from "@/mixins/screenSize"
import servicesRepository from "@/services/servicesRepository"

export default {
  name: "ReasonField",
  mixins: [screenSize],
  data() {
    return {
      selectedItem: null,
      reasonItems: [],
    }
  },
  computed: {
    selectedItemComputed: {
      get() {
        return this.selectedItem
      },
      set(value) {
        this.selectedItem = value
        this.$emit("send-up-reason", value)
      },
    },
  },
  mounted() {
    this.fetchReasonCodeList()
  },
  methods: {
    async fetchReasonCodeList() {
      const response = await servicesRepository(
        this
      ).settlementServices.httpGetSettlementReasonCode()
      const {
        data: { data },
      } = response

      this.reasonItems = [...data]
    },
  },
}
</script>

<template>
  <v-layout
    class="mt-0 pa-0 justify-space-between"
    :class="{ 'd-flex flex-column': reuseIsMobile }"
  >
    <v-flex class="label"> بابت </v-flex>
    <v-flex class="flex-column withlabel big">
      <v-select
        v-model="selectedItemComputed"
        height="40"
        outlined
        :items="reasonItems"
        item-value="code"
        item-text="title"
      ></v-select>
    </v-flex>
  </v-layout>
</template>

<style scoped lang="scss">
:deep(.v-text-field--enclosed .v-input__append-inner) {
  margin-top: 6px;
}
</style>
