<template>
  <v-card class="singleBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0">اصلاح تاریخ ثبت شرکت</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          ref="btn"
          text
          dark
          :loading="loading"
          :disabled="disabled"
          class="btn_small_normal"
          @click="sendForm"
        >
          ذخیره
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column justify-space-between>
      <v-flex>
        <v-form ref="form" v-model="valid">
          <div class="font_2">
            تاریخ ثبت با تاریخ واقعی شرکت همخوانی ندارد، اصلاح کنید.
          </div>
          <v-divider class="mb-7" style="border-style: dashed" />
          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <InputContainer
              label="تاریخ ثبت شرکت"
              class="custom-input"
              :optional="false"
            >
              <template>
                <v-flex class="withlabel big">
                  <v-text-field
                    v-model="registerDateText"
                    solo
                    flat
                    height="40"
                    outlined
                    :rules="businessRegistrationDateRules"
                    @focus="$store.commit('setShowStateOfDatePicker', true)"
                    @keypress="(e) => e.preventDefault()"
                  >
                    <template #message="message">
                      <div v-html="message.message"></div>
                    </template>
                  </v-text-field>
                </v-flex>
              </template>
            </InputContainer>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <DatePicker @setDate="setTheDate($event)" />
  </v-card>
</template>

<script>
import InputContainer from "../elements/inputContainer.vue"
import DatePicker from "@/components/modals/datePicker.vue"

export default {
  name: "EditRegisterDate",
  components: {
    InputContainer,
    DatePicker,
  },
  data() {
    return {
      valid: false,
      registerDate: "",
      loading: false,
      registerDateText: "",
      businessRegistrationNo: null,
      businessRegistrationDateRules: [
        (v) => !!v || "<span> وارد کردن تاریخ ثبت شرکت الزامی است</span>",
      ],
    }
  },
  computed: {
    disabled() {
      return !this.valid || this.registerDateText == ""
    },
  },
  methods: {
    setTheDate(event) {
      this.registerDateText = event.dateText
      this.registerDate = event.date
    },
    sendForm() {
      this.loading = true

      let data = {
        wage: this.$store.state.ipg.wage,
        url: this.$store.state.ipg.url,
        urls: this.$store.state.ipg.urls,
        avatar: this.$store.state.business.avatar,
        tax_code: this.$store.state.business.tax_code,
        register_date: this.registerDate, //new field
      }
      this.$http
        .post(this.$store.getters.apiUrlParam + "/ipg/update", data, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.$store.commit(
            "callSuccessSnackbar",
            "تاریخ ثبت شرکت با موفقیت ذخیره شد"
          )
          // this.$store.commit('updateUserOrBusinessValue', {
          //   key: 'register_date',
          //   value: this.registerDate,
          // })
          if (!this.$store.state.isUser) {
            this.$store
              .dispatch(
                "retrieveBusinessObject",
                this.$store.state.business.business_name
              )
              .then((response) => {
                //DO Nothing
              })
              .catch((error) => {
                //callsnackbar
              })
          }
          this.loading = false
          this.$emit("closeModal")
        })
        .catch((error) => {
          //callsnackbar
          this.loading = false
        })
    },
  },
}
</script>
