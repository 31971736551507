<template>
  <v-card class="headerBox">
    <v-toolbar flat height="60" class="fixed_toolbar">
      <v-btn icon @click.native="$emit('closeModal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title v-if="type == 'edit'" class="pr-0">
        ویرایش پرداخت خودکار
      </v-toolbar-title>
      <v-toolbar-title v-else class="pr-0"> پرداخت خودکار </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          v-if="type == 'edit'"
          text
          dark
          class="btn_small_normal"
          :disabled="!valid"
          :loading="loading"
          @click="sendForm()"
        >
          ذخیره
        </v-btn>
        <v-btn
          v-else
          text
          dark
          class="btn_small_normal"
          :disabled="!valid"
          :loading="loading"
          @click="sendForm()"
          >ساخت پرداخت خودکار</v-btn
        >
      </v-toolbar-items>
    </v-toolbar>

    <v-layout column>
      <v-flex>
        <v-card flat>
          <v-layout>
            <v-flex>
              <v-form ref="form" v-model="valid">
                <v-layout
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-space-between
                  align-start
                >
                  <v-flex class="label" style="width: 110px">
                    آدرس وب‌سایت
                    <span>*</span>
                  </v-flex>
                  <v-flex class="withlabel big comboboxSmallchip">
                    <v-combobox
                      v-model="urls"
                      hide-selected
                      multiple
                      small-chips
                      solo
                      flat
                      outlined
                      hint="بعد از وارد کردن هر آدرس، دکمه «اینتر» را بزنید. آدرس را با http یا https وارد کنید."
                      deletable-chips
                    >
                      <template #message="message">
                        <div v-html="message.message"></div>
                      </template>
                    </v-combobox>
                  </v-flex>
                </v-layout>

                <v-layout
                  :column="$vuetify.breakpoint.xsOnly"
                  justify-start
                  align-start
                >
                  <v-flex class="label"> کارمزد تراکنش </v-flex>
                  <v-flex class="withlabel">
                    <v-select
                      v-model="wage_type"
                      :items="selectItems"
                      solo
                      flat
                      height="40"
                      outlined
                    />
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "Add",
  props: ["type"],
  data: function () {
    return {
      valid: false,
      loading: false,
      subscription: {},
      urls: [],
      wage_type: "از پرداخت‌کننده دریافت شود",
      selectItems: ["از خودم کسر شود", "از پرداخت‌کننده دریافت شود"],
      panel: [false],
    }
  },
  computed: {
    disable() {
      if (!this.loading && this.valid) return true
      else return false
    },
  },
  mounted() {
    if (this.type == "edit") {
      this.wage_type =
        this.$store.state.subscription.wage_type == "APPLICATION_SELF"
          ? "از خودم کسر شود"
          : "از پرداخت‌کننده دریافت شود"
      this.urls = this.$store.state.subscription.urls
    }
  },
  methods: {
    sendForm() {
      this.loading = true
      let data = {
        wage_type:
          this.wage_type == "از خودم کسر شود"
            ? "APPLICATION_SELF"
            : "APPLICATION_USER",
        domain: this.urls,
      }
      if (this.type == "edit") {
        this.$http
          .put(
            this.$store.getters.apiUrlParam +
              "/subscription/application/update",
            data,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            this.subscription = response.data.result.application
            this.$store.commit("addSubscription", this.subscription)
            this.$emit("closeModal", { status: true })
            this.loading = false
          })
          .catch((error) => {
            //callsnackbar
            this.loading = false
          })
      } else {
        this.$http
          .post(
            this.$store.getters.apiUrlParam + "/subscription/application/store",
            data,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + this.$store.state.token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            this.subscription = response.data.result.application
            this.$store.commit("addSubscription", this.subscription)
            var newBusiness = this.$store.state.business
            newBusiness.tools.subscription.is_created = true
            this.$store.commit("setBusiness", newBusiness)
            this.$emit("finishAdd")
            this.$emit("closeModal", { status: true })
            this.loading = false
          })
          .catch((error) => {
            //callsnackbar
            this.loading = false
          })
      }
    },
  },
}
</script>
