<template>
  <div>
    <DatePicker
      v-model="selectedDateComputed"
      :show="$store.state.isShowDatePicker"
      type="date"
      format="jDD jMMMM jYYYY"
      display-format="jDD jMMMM jYYYY"
      color="#4C9F87"
      :max="today"
      custom-input=".custom-input"
      @close="$store.commit('setShowStateOfDatePicker', false)"
    />
  </div>
</template>

<script>
import DatePicker from "vue-persian-datetime-picker"
import momentJalaali from "moment-jalaali"

export default {
  components: {
    DatePicker,
  },
  props: {
    maxDateIsToday: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      day: null,
      month: null,
      numericMonth: null,
      year: null,
      monthItem: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      selectedDate: "",
      today: "",
    }
  },
  computed: {
    selectedDateComputed: {
      get() {
        return this.selectedDate
      },
      set(val) {
        const [day, month, year] = val.split(" ")
        this.year = year
        this.month = month
        this.numericMonth =
          this.monthItem.findIndex((item) => item === month) + 1
        this.day = day
        const date = `${Number(year)}/${this.numericMonth}/${Number(day)}`

        this.$emit("setDate", { dateText: val, date })
      },
    },
  },
  mounted() {
    if (this.maxDateIsToday) {
      this.today = momentJalaali(new Date().toISOString().split("T")[0]).format(
        "jDD jMMMM jYYYY"
      )
    } else {
      this.today = ""
    }
  },
}
</script>
