<template>
  <v-text-field
    ref="nationalCode"
    v-model="nationalCode"
    :placeholder="placeholder"
    :error-messages="messageNationalCode"
    :rules="nationalCodeRules"
    solo
    flat
    height="40"
    outlined
    @keydown="numberKeyHandler($event)"
  >
    <template #message="message">
      <div v-html="message.message"></div>
    </template>
  </v-text-field>
</template>

<script>
import servicesRepository from "@/services/servicesRepository"

export default {
  name: "NationalCode",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    isOptional: {
      type: Boolean,
      default: true,
    },
    isNeedToCheckRegistered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nationalCode: "",
      nationalCodeRules: [
        (v) =>
          !!v || this.isOptional || "<span> وارد کردن کدملی الزامی است </span>",
        (v) =>
          this.NationalCodeValidator(v) || "<span> کدملی نادرست است </span>",
      ],
      messageNationalCode: "",
    }
  },
  watch: {
    nationalCode(newValue) {
      this.messageNationalCode = null
      this.nationalCode = this.convertNumbers2English(newValue).replace(
        /[^0-9.]/g,
        ""
      )
      this.$refs.nationalCode.lazyValue = this.nationalCode
      this.$emit("setValue", newValue)
    },
  },
  methods: {
    NationalCodeValidator(value) {
      if (value.length === 0) return true
      value = this.nationalCode
      if (value !== null && value.length > 1 && value.length === 10) {
        if (
          value === "0000000000" ||
          value === "1111111111" ||
          value === "2222222222" ||
          value === "3333333333" ||
          value === "4444444444" ||
          value === "5555555555" ||
          value === "6666666666" ||
          value === "7777777777" ||
          value === "8888888888" ||
          value === "9999999999"
        ) {
          return false
        }
        let numbers = value.split("")
        if (numbers.length < 10) return false
        let sum = 0
        for (let i = 10; i > 1; i--) {
          sum += numbers[10 - i] * i
        }
        let r = sum % 11
        if ((r >= 2 && r == 11 - numbers[9]) || (r < 2 && r == numbers[9])) {
          if (this.isNeedToCheckRegistered) {
            this.checkNationalCodeRegistration()
          }
          return true
        }
        return false
      } else {
        this.messageNationalCode = null
        return false
      }
    },

    async checkNationalCodeRegistration() {
      let nationalCodeObj = {
        national_code: this.nationalCode,
      }
      const response = await servicesRepository(
        this
      ).checkServices.httpPostCheckUniqueness(nationalCodeObj)

      if (response.data.status === 1) {
        this.messageNationalCode = null
        //this.$refs.code.validate()
      } else {
        this.messageNationalCode = "<span>این کد ملی قبلا ثبت شده است </span>"
      }
    },
  },
}
</script>
