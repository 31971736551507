<template>
  <left-modal
    toolbar-name="جزییات برداشت در نوبت"
    @closeModal="$emit('closeModal')"
  >
    <general-card>
      <div class="d-flex">
        <v-img
          class="mr-1 ml-2"
          :src="require('@/assets/img/box-green-question.svg')"
          width="26"
          height="26"
        />
        <div>
          <div class="mb-1 font-size-12 font-weight-bold van_color02--text">
            برداشت در نوبت چیست؟
          </div>
          <div class="font-size-12 van_color03--text">
            این قابلیت امکان ثبت درخواست برداشت بیشتر از موجودی کیف‌پول و یا
            حساب را به شما می‌دهد. این تراکنش‌ها، بعد از افزایش موجودی در صف
            بانک قرار می‌گیرند.
            <br />
            برداشت‌های در نوبت از طریق میزکار قابل مشاهده هستند.
          </div>
        </div>
      </div>
      <div class="d-flex mt-5">
        <v-img
          class="mr-1 ml-2"
          :src="require('@/assets/img/box-green-question.svg')"
          width="26"
          height="26"
        />
        <div>
          <div class="mb-1 font-size-12 font-weight-bold van_color02--text">
            پس از افزایش موجودی کیف‌پول و یا حساب چه می‌شود؟
          </div>
          <div class="font-size-12 van_color03--text">
            برداشت‌های در نوبت، پس از افزایش موجودی کیف‌پول و یا حساب به صورت
            خودکار در صف بانک قرار گرفته و در لیست واریز و برداشت قابل مشاهده
            هستند.
          </div>
        </div>
      </div>
      <div class="d-flex mt-5">
        <v-img
          class="mr-1 ml-2"
          :src="require('@/assets/img/box-green-question.svg')"
          width="26"
          height="26"
        />
        <div>
          <div class="mb-1 font-size-12 font-weight-bold van_color02--text">
            آیا امکان لغو برداشت‌های در نوبت وجود دارد؟
          </div>
          <div class="font-size-12 van_color03--text">
            بله این برداشت‌ها تا قبل از افزایش موجودی کیف‌پول و یا حساب، از طریق
            لیست برداشت‌های در نوبت در میز کار قابلیت لغو دارند.
          </div>
        </div>
      </div>
      <div class="d-flex mt-5">
        <v-img
          class="mr-1 ml-2"
          :src="require('@/assets/img/box-green-question.svg')"
          max-width="26"
          height="26"
        />
        <div>
          <div class="mb-1 font-size-12 font-weight-bold van_color02--text">
            تا چه زمانی امکان ارسال به بانک دارند؟
          </div>
          <div class="font-size-12 van_color03--text">
            این تراکنش‌ها تا ۳ ماه پس از ثبت امکان ارسال به بانک را دارند.
          </div>
        </div>
      </div>
    </general-card>
  </left-modal>
</template>

<script>
import LeftModal from "../../modals/leftModal"
import GeneralCard from "@/components/elements/generalCard"
export default {
  name: "QueueDetails",
  components: { GeneralCard, LeftModal },
  props: ["isChanged"],
  data() {
    return {
      bankList: [],
    }
  },
  computed: {
    bankListIsEmpty() {
      return !this.bankList?.length > 0
    },
  },
  watch: {
    isChanged: function (newValue, oldValue) {
      this.bankList = []
      this.getBankList()
    },
  },
  mounted() {
    this.getBankList()
  },
  methods: {
    getBankList() {
      this.$http
        .get(
          this.$store.getters.apiWithVersionParam("3") + "/settlement/banks",
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.bankList = response?.data?.data?.filter(
            (b) => b.a2a.is_active && b.a2a.has_ability
          )
        })
        .catch(() => {
          //callsnackbar
        })
    },
  },
}
</script>
