import { render, staticRenderFns } from "./AddIpgModal.vue?vue&type=template&id=b70ea3fe&scoped=true&"
import script from "./AddIpgModal.vue?vue&type=script&lang=js&"
export * from "./AddIpgModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b70ea3fe",
  null
  
)

export default component.exports