const apiHeaders = (context) => ({
  "Content-Type": "application/json",
  authorization: "Bearer " + context.$store.state.token,
  Accept: "application/json",
})

export default (context) => ({
  httpGetAccountsList() {
    return context.$http.get(
      `${context.$store.getters.apiUrlParamV3}/settlement/account`,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpGetLastBalance(iban) {
    return context.$http.get(
      `${context.$store.getters.apiUrlParamV3}/settlement/account/${iban}/last-balance`,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpUpdateAccountName(iban, name) {
    return context.$http.put(
      `${context.$store.getters.apiUrlParamV3}/settlement/account/${iban}/name`,
      { name },
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpGetSettlementInfo() {
    return context.$http.get(
      `${context.$store.getters.apiUrlParam}/settlement/info`,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpPostDailySettlementLimit(selectedIban) {
    return context.$http.post(
      `${context.$store.getters.ibanApiUrlParam}/daily-settlement-limit`,
      { iban: selectedIban }
    )
  },
  httpPostDailySettlementLimitForActionsFile(axios) {
    return axios.post(
      `${context.getters.ibanApiUrlParam}/daily-settlement-limit`
    )
  },
  httpGetSettlementReasonCode() {
    return context.$http.get(
      `${context.$store.state.api_base_url_v3}/settlement/reason-code`,
      {
        headers: apiHeaders(context),
      }
    )
  },
})
