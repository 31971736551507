<template>
  <v-card height="100%" elevation="0">
    <v-toolbar flat height="60">
      <v-btn icon @click="$emit('close-ipg-modal')">
        <v-icon color="van_color03" size="21"> close </v-icon>
      </v-btn>
      <v-toolbar-title class="pr-0"> درگاه پرداخت </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-btn
          text
          dark
          class="btn_small_normal"
          :disabled="!$store.state.ipg.ipgFormSubmitBtnActiveState"
          :loading="loading"
          @click="changeCreatingIpgStatus(true)"
        >
          ساخت درگاه
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <CreatingIpgStep
      class="d-flex flex-column align-center py-5"
      style="background: white"
      is-in-modal
      @loading-state="loading = $event"
    />
  </v-card>
</template>

<script>
import servicesRepository from "@/services/servicesRepository"
import { mapMutations } from "vuex"
import CreatingIpgStep from "@/components/OnBoarding/elements/CreatingIpg/CreatingIpgStep.vue"
import sortFinancialListOnTop from "@/utils/sortFinancialListOnTop"

export default {
  name: "AddIpgModal",
  components: {
    CreatingIpgStep,
  },
  provide() {
    return {
      mccCodeGuidanceItemsStep4: this.mccCodeGuidanceItemsStep4,
      websiteUrlGuidanceItems: this.websiteUrlGuidanceItems,
      taxCodeGuidanceItems: this.taxCodeGuidanceItems,
      shebaGuidanceItems: this.shebaGuidanceItems,
      logoGuidanceItems: this.logoGuidanceItems,
    }
  },
  data() {
    return {
      mccCodeGuidanceItemsStep4: [
        "حوزه فعالیت اطلاعات مهمی است که در صورت صحیح نبودن درگاه پراخت شما از سمت شاپرک رد می‌شود",
        "حوزه فعالیت با آدرس وب سایت باید همخوانی داشته باشد",
      ],
      websiteUrlGuidanceItems: [
        "حوزه فعالیت با آدرس وب سایت باید همخوانی داشته باشد",
        "روی آدرس وب سایت وارد شده باید اینماد داشته باشید",
      ],
      taxCodeGuidanceItems: [
        "درخواست فعال‌سازی درگاه پرداخت نیازمند داشتن کدرهگیری مالیاتی است.",
        "برای تایید درگاه سمت شاپرک لازمه که ثبت نام کد مالیاتی تا گام ۴ انجام شده باشد",
      ],
      wageGuidanceItems: [
        "کسر کارمزد از پرداخت کننده تراکنش یا از کیف پول شما",
        "این بخش پس از ثبت، امکان ویرایش دارد",
      ],
      shebaGuidanceItemsForLegalBusiness: [
        "شماره شبای حقوقی کسب‌وکار را وارد کنید",
        "این شماره شبا برای تسویه حساب روزانه مبالغ واریزی درگاه پرداخت شما استفاده خواهد شد",
        "این شماره شبا باید با شماره شبا ثبت شده در شاپرک همخوانی داشته باشد",
      ],
      shebaGuidanceItemsForRealBusiness: [
        "شماره شبا باید متعلق به خودتان باشد",
        "این شماره شبا برای تسویه حساب روزانه مبالغ واریزی درگاه پرداخت شما استفاده خواهد شد",
        "این شماره شبا باید با شماره شبا ثبت شده در شاپرک همخوانی داشته باشد",
      ],
      logoGuidanceItems: ["لوگو در درگاه پرداخت شما نمایش داده خواهد شد"],
      ipgFormValidation: false,
      loading: false,
    }
  },
  computed: {
    shebaGuidanceItems() {
      return this.$store.state.business.business_type === "legal"
        ? this.shebaGuidanceItemsForLegalBusiness
        : this.shebaGuidanceItemsForRealBusiness
    },
    category_id: {
      get() {
        return this.$store.state.business.category_id
      },
      set(new_category_id) {
        this.$store.commit("updateCategoryId", new_category_id)
      },
    },
    isMobileMode() {
      return this.$vuetify.breakpoint.xsOnly
    },
  },
  mounted() {
    this.getBusinessCategories()
  },
  methods: {
    ...mapMutations("newbusiness", [
      "setBusinessCategories",
      "changeCreatingIpgStatus",
    ]),
    getBusinessCategories() {
      servicesRepository(this)
        .informationService.httpGetCategories()
        .then((response) => {
          this.setBusinessCategories(
            sortFinancialListOnTop(Object.entries(response.data.data))
          )
        })
        .catch(() => {
          window.location.reload()
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
