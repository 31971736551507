const subscription = {
  state() {
    return {
      //$store.state.ipg.api_key
      urls: [],
      wage_type: "APPLICATION_SELF",
      status: 0,
      successful_withdrawals_count: 0,
      transactions_sum: 0,
      customers_count: 0,
      banks: [],
      has_secret_key: false,
      subscriptionLoaded: false,
    }
  },
  mutations: {
    setBanks(state, obj) {
      state.banks = [...obj]
    },
    setBankActive(state, obj) {
      state.banks.filter((item) => item.code === obj).is_active = true
      state.banks = [...state.banks]
    },
    setBankDeactivate(state, obj) {
      state.banks.filter((item) => item.code === obj).is_active = false
      state.banks = [...state.banks]
    },
    addSubscription(state, obj) {
      state.urls = obj.domain
      state.wage_type = obj.wage_type
      state.status = obj.status
      state.successful_withdrawals_count = obj.successful_withdrawals_count
      state.transactions_sum = obj.transactions_sum
      state.customers_count = obj.customers_count
      state.has_secret_key = obj.has_secret_key
      state.subscriptionLoaded = true
    },
    updateSubscription(state, obj) {
      state.urls = obj.domain
      state.wage_type = obj.wage_type
    },
    updateHasSecretKey(state, value) {
      state.has_secret_key = value
    },
    destroySubscription(state) {
      ;(state.urls = []),
        (state.wage_type = "APPLICATION_SELF"),
        (state.status = 0),
        (state.successful_withdrawals_count = 0),
        (state.transactions_sum = 0)
      state.customers_count = 0
    },
  },
  getters: {},
}

export default subscription
